import React, { useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [routes, setRoutes] = useState([]);

  return (
    <div id="wrapper" className="wrapper">
      <Sidebar />
      <div style={{ width: "100%" }}>
        <div id="content" className="content" style={{ width: "100%" }}>
          <Header routes={routes} />
          <div>{children}</div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
