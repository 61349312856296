import React from "react";
import {
  Button,
  Modal as ReactstrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const CustomModal = ({
  modalState,
  setModalState,
  title,
  className,
  text,
  OkAction,
  size,
  disabled,
  backdrop = true,
  disableFooter,
  children,
}) => {
  const toggle = () => setModalState(!modalState);
  return (
    <ReactstrapModal
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <div id="confirm-popup" class="popup-cont alert">
        <div className="modal">
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M8,0c1.5,0,2.8,0.4,4,1.1c1.2,0.7,2.2,1.7,2.9,2.9C15.6,5.2,16,6.5,16,8c0,1.5-0.4,2.8-1.1,4c-0.7,1.2-1.7,2.2-2.9,2.9 C10.8,15.6,9.5,16,8,16c-1.5,0-2.8-0.4-4-1.1c-1.2-0.7-2.2-1.7-2.9-2.9C0.4,10.8,0,9.5,0,8c0-1.5,0.4-2.8,1.1-4 C1.8,2.8,2.8,1.8,4,1.1C5.2,0.4,6.5,0,8,0z M8,15c1.3,0,2.5-0.3,3.5-1c1-0.6,1.9-1.5,2.5-2.5c0.6-1.1,0.9-2.2,1-3.5 c0-1.3-0.3-2.5-1-3.5c-0.6-1-1.5-1.9-2.5-2.5C10.5,1.3,9.3,1,8,1C6.7,1,5.5,1.3,4.5,2C3.4,2.6,2.6,3.4,2,4.5C1.3,5.5,1,6.7,1,8 c0,1.3,0.3,2.5,1,3.5c0.6,1,1.5,1.9,2.5,2.5C5.5,14.7,6.7,15,8,15z M8,10.8c0.5,0,0.7,0.3,0.8,0.8c0,0.5-0.3,0.7-0.8,0.8 c-0.5,0-0.7-0.3-0.8-0.8C7.3,11,7.5,10.8,8,10.8z M8,9.5C7.7,9.5,7.5,9.3,7.5,9V4c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v5 C8.5,9.3,8.3,9.5,8,9.5z" />
            </g>
          </svg>

          <p>{text}</p>
          {children}
          <div className="buttons-cont center">
            <button className="btn secondary close-popup" onClick={toggle}>
              Cancelar
            </button>
            <button
              id="activate-ok"
              className="btn close-popup"
              onClick={() => {
                OkAction();
                toggle();
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </ReactstrapModal>
  );
};

export default CustomModal;
