import React from "react";
import PropTypes from "prop-types";

const PageHeading = ({ title, description, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <h1 className="h3 mb-1 text-gray-800">{title}</h1>
      <p className="mb-4">{description}</p>
    </div>
  );
};

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PageHeading;
