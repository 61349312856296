import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../context/userContext";
import logoW from "../../assets/img/logo-white.svg";

const Sidebar = () => {
  const { userInfo } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  return (
    <>
      <div className={`bar-menu ${isOpen ? "open" : ""} `}>
        <div className="logo-cont">
          <img src={logoW} className="logo" />
        </div>
        <button
          id="expand"
          className="expand"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M4.1,15.2c0-0.1,0-0.3,0.1-0.4L10.5,8L4.3,1.2c-0.2-0.3-0.2-0.5,0-0.8c0.3-0.2,0.5-0.2,0.8,0l6.7,7.2 c0.2,0.3,0.2,0.5,0,0.8l-6.7,7.2c-0.3,0.2-0.6,0.2-0.8,0C4.2,15.5,4.1,15.4,4.1,15.2z" />
            </g>
          </svg>
        </button>

        <Link
          to="/companies"
          className={`${
            location.pathname.startsWith("/companies") ? "active" : ""
          }`}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M0.6,0.6c0.3-0.3,0.7-0.5,1.2-0.5h3.4c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2C6.1,6.7,5.7,6.9,5.2,6.9H1.8c-0.5,0-0.9-0.2-1.2-0.5C0.3,6.1,0.1,5.7,0.1,5.2V1.8C0.1,1.3,0.3,0.9,0.6,0.6z M0.6,9.6c0.3-0.3,0.7-0.5,1.2-0.5h3.4c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5H1.8c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2v-3.4C0.1,10.3,0.3,9.9,0.6,9.6z M1.3,5.2c0,0.4,0.2,0.5,0.6,0.6h3.4c0.4,0,0.5-0.2,0.6-0.6V1.8c0-0.4-0.2-0.5-0.6-0.6H1.8c-0.4,0-0.5,0.2-0.6,0.6V5.2zM1.3,14.2c0,0.4,0.2,0.5,0.6,0.6h3.4c0.4,0,0.5-0.2,0.6-0.6v-3.4c0-0.4-0.2-0.5-0.6-0.6H1.8c-0.4,0-0.5,0.2-0.6,0.6V14.2z M15.4,0.6c0.3,0.3,0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5h-3.4c-0.5,0-0.9-0.2-1.2-0.5C9.3,6.1,9.1,5.7,9.1,5.2V1.8c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5h3.4C14.7,0.1,15.1,0.3,15.4,0.6z M9.6,9.6c0.3-0.3,0.7-0.5,1.2-0.5h3.4c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5h-3.4c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2v-3.4C9.1,10.3,9.3,9.9,9.6,9.6z M10.8,1.2c-0.4,0-0.5,0.2-0.6,0.6v3.4c0,0.4,0.2,0.5,0.6,0.6h3.4c0.4,0,0.5-0.2,0.6-0.6V1.8c0-0.4-0.2-0.5-0.6-0.6H10.8z M10.2,14.2c0,0.4,0.2,0.5,0.6,0.6h3.4c0.4,0,0.5-0.2,0.6-0.6v-3.4c0-0.4-0.2-0.5-0.6-0.6h-3.4c-0.4,0-0.5,0.2-0.6,0.6V14.2z" />
            </g>
          </svg>
          <span>Inicio</span>
        </Link>
        <Link
          hidden={!userInfo?.isAdmin}
          to="/users"
          className={`${
            location.pathname.startsWith("/users") ? "active" : ""
          }`}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M4.1,7.3C4,7.2,3.8,7.2,3.7,7.2H2.1c-0.3,0-0.6,0.1-0.8,0.4C1,7.8,0.9,8.1,0.9,8.5v0.3c0,0.2-0.1,0.4-0.4,0.4 C0.3,9.2,0.1,9,0.1,8.8V8.5c0-0.6,0.2-1.1,0.6-1.5C1,6.6,1.5,6.4,2.1,6.4h1.6c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.2,0.5 C4.5,7.3,4.4,7.4,4.1,7.3z M3.3,5.6c-0.6,0-1-0.2-1.4-0.6C1.5,4.7,1.3,4.2,1.3,3.7c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.8-0.6,1.4-0.6 c0.6,0,1,0.2,1.4,0.6C5,2.6,5.2,3.1,5.2,3.7c0,0.6-0.2,1-0.6,1.4C4.3,5.4,3.8,5.6,3.3,5.6z M3.3,2.5c-0.3,0-0.6,0.1-0.8,0.3 C2.2,3.1,2.1,3.3,2.1,3.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3C4.3,4.3,4.4,4,4.5,3.7 c0-0.3-0.1-0.6-0.3-0.8C3.9,2.6,3.6,2.5,3.3,2.5z M9.2,10.4c1,0,1.8,0.3,2.5,1c0.7,0.6,1,1.4,1,2.3c0,0.2-0.1,0.4-0.2,0.5 c-0.1,0.1-0.3,0.2-0.5,0.2H4c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.9,0.4-1.7,1-2.3c0.7-0.6,1.5-0.9,2.5-1H9.2z M4.1,13.5h7.9c0-0.7-0.3-1.2-0.8-1.7c-0.5-0.4-1.1-0.7-1.9-0.7H6.7c-0.7,0-1.3,0.2-1.8,0.7C4.4,12.3,4.1,12.8,4.1,13.5z M8,9.6 c-0.7,0-1.3-0.3-1.8-0.7C5.7,8.3,5.5,7.7,5.5,7c0-0.7,0.3-1.3,0.7-1.8C6.7,4.7,7.3,4.5,8,4.5c0.7,0,1.3,0.3,1.8,0.7 c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.3,1.3-0.7,1.8C9.3,9.3,8.7,9.6,8,9.6z M8,5.2c-0.5,0-0.9,0.2-1.3,0.5S6.2,6.5,6.2,7 c0,0.5,0.2,0.9,0.5,1.3C7.1,8.6,7.5,8.8,8,8.8c0.5,0,0.9-0.2,1.3-0.5C9.6,7.9,9.8,7.5,9.8,7c0-0.5-0.2-0.9-0.5-1.3 C8.9,5.4,8.5,5.3,8,5.2z M12.7,5.6c-0.6,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.6-0.2,1-0.6,1.4C13.8,5.4,13.3,5.6,12.7,5.6z M13.9,6.4c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.5v0.3c0,0.2-0.1,0.4-0.4,0.4c-0.2,0-0.4-0.1-0.4-0.4V8.5c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4h-1.6c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0-0.5-0.2c-0.1-0.2,0-0.4,0.2-0.5c0.2-0.1,0.5-0.2,0.8-0.2H13.9z M12.7,2.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C13.3,2.6,13.1,2.5,12.7,2.5z" />
            </g>
          </svg>
          <span>Usuarios</span>
        </Link>
        <Link
          hidden={!userInfo?.isAdmin}
          to="/admin/companies"
          className={`${
            location.pathname.startsWith("/admin/companies") ? "active" : ""
          }`}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M0.1,8.7c0-0.5,0.2-0.9,0.5-1.2l3.2-2.9c0.3-0.3,0.7-0.4,1.1-0.4s0.7,0.1,1.1,0.4l3.2,2.9c0.3,0.3,0.5,0.7,0.5,1.2v4.1 c0,0.4-0.2,0.8-0.5,1.1S8.4,14.3,8,14.3H1.7c-0.4,0-0.8-0.2-1.1-0.5s-0.5-0.7-0.5-1.1V8.7z M0.9,12.7c0,0.2,0.1,0.4,0.2,0.6 s0.3,0.2,0.6,0.2H8c0.2,0,0.4-0.1,0.6-0.2s0.2-0.3,0.2-0.6V8.7c0-0.2-0.1-0.4-0.2-0.6L5.4,5.2C5.2,5.1,5,5,4.8,5 C4.6,5,4.5,5.1,4.3,5.2L1.1,8.1C1,8.3,0.9,8.5,0.9,8.7h0V12.7z M6.2,8.2c0.1,0.1,0.2,0.3,0.2,0.6v1.6c0,0.2-0.1,0.4-0.2,0.6 s-0.3,0.2-0.6,0.2H4c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.6V8.8c0-0.2,0.1-0.4,0.2-0.6S3.8,8,4,8h1.6C5.9,8,6,8.1,6.2,8.2z M4,8.8v1.6h1.6V8.8H4z M7.2,4.8L6.4,4V3.3c0-0.4,0.2-0.8,0.5-1.1C7.2,1.8,7.6,1.7,8,1.7h6.3c0.4,0,0.8,0.2,1.1,0.5 s0.5,0.7,0.5,1.1v9.5c0,0.4-0.2,0.8-0.5,1.1s-0.7,0.5-1.1,0.5H10c0.2-0.2,0.3-0.5,0.3-0.8h4c0.2,0,0.4-0.1,0.6-0.2s0.2-0.3,0.2-0.6 V3.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.6-0.2H8c-0.2,0-0.4,0.1-0.6,0.2C7.3,2.8,7.2,3,7.2,3.3V4.8z M10.5,3.9 c0.1,0.1,0.2,0.3,0.2,0.6v0.8c0,0.2-0.1,0.4-0.2,0.6S10.2,6,10,6H9.2C9,6,8.8,5.9,8.6,5.8S8.4,5.5,8.4,5.2V4.4 c0-0.2,0.1-0.4,0.2-0.6S9,3.7,9.2,3.7H10C10.2,3.7,10.4,3.7,10.5,3.9z M10,5.2V4.4H9.2v0.8H10z M11.8,3.9c0.1-0.1,0.3-0.2,0.6-0.2 h0.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6v0.8c0,0.2-0.1,0.4-0.2,0.6S13.4,6,13.1,6h-0.8c-0.2,0-0.4-0.1-0.6-0.2 s-0.2-0.3-0.2-0.6V4.4C11.6,4.2,11.6,4,11.8,3.9z M13.7,7c0.1,0.1,0.2,0.3,0.2,0.6v0.8c0,0.2-0.1,0.4-0.2,0.6 c-0.1,0.1-0.3,0.2-0.6,0.2h-0.8c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6V7.6c0-0.2,0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2h0.8 C13.4,6.8,13.6,6.9,13.7,7z M11.8,10.2c0.1-0.1,0.3-0.2,0.6-0.2h0.8c0.2,0,0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6v0.8 c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2h-0.8c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-0.8C11.6,10.5,11.6,10.3,11.8,10.2z M12.3,4.4v0.8h0.8V4.4H12.3z M13.1,8.4V7.6h-0.8v0.8H13.1z M12.3,10.8v0.8h0.8v-0.8H12.3z" />{" "}
            </g>
          </svg>
          <span>Empresas</span>
        </Link>
        <Link
          hidden={!userInfo?.isAdmin}
          to="/access/companies"
          className={`${
            location.pathname.includes("/access/companies") ? "active" : ""
          }`}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M1.2,1.2c0.4-0.4,0.9-0.6,1.5-0.6h10.6c0.6,0,1.1,0.2,1.5,0.6s0.6,0.9,0.6,1.5v10.6c0,0.6-0.2,1.1-0.6,1.5 s-0.9,0.6-1.5,0.6H2.7c-0.6,0-1.1-0.2-1.5-0.6s-0.6-0.9-0.6-1.5V2.7C0.6,2.1,0.8,1.6,1.2,1.2z M1.6,13.3c0,0.3,0.1,0.6,0.3,0.8 c0.2,0.2,0.5,0.3,0.8,0.3h10.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V2.7c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3 H2.7c-0.3,0-0.6,0.1-0.8,0.3C1.7,2.1,1.6,2.4,1.6,2.7V13.3z M6.6,10.5L4.5,8.4c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0 l1.8,1.7l3.9-3.8c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-4.2,4.2C7.1,10.7,6.8,10.7,6.6,10.5z" />{" "}
            </g>
          </svg>
          <span>Accesos</span>
        </Link>
      </div>

      {/* 
<!-- Divider --> */}
      {/*   <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link to="/companies" className="nav-link">
            <i className="fas fa-home"></i>
            <span>Inicio</span>
          </Link>
        </li> */}

      {/* <!-- Divider --> */}
      {/*         <hr hidden={!userInfo?.isAdmin} className="sidebar-divider" />
       */}
      {/* <!-- Heading --> */}
      {/* <div className="sidebar-heading">Interface</div> */}

      {/* <!-- Nav Item - Pages Collapse Menu --> */}
      {/* <li className="nav-item">
          <span
            className="nav-link collapsed c-pointer"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Components</span>
          </span>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <Link to="/companies" className="collapse-item">
                Buttons
              </Link>
              <Link to="/companies" className="collapse-item">
                Cards
              </Link>
            </div>
          </div>
        </li> */}

      {/* <!-- Nav Item - Utilities Collapse Menu --> */}
      {/* <li className="nav-item">
          <span
            className="nav-link collapsed c-pointer"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i className="fas fa-fw fa-wrench"></i>
            <span>Utilities</span>
          </span>
          <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Utilities:</h6>
              <Link to="/companies" className="collapse-item">
                Colors
              </Link>
              <Link to="/companies" className="collapse-item">
                Borders
              </Link>
              <Link to="/companies" className="collapse-item">
                Animations
              </Link>
              <Link to="/companies" className="collapse-item">
                Other
              </Link>
            </div>
          </div>
        </li> */}
      {/* <!-- Nav Item - Charts --> */}
      {/*   <li hidden={!userInfo?.isAdmin} className="nav-item">
          <Link to="/users" className="nav-link">
            <i className="fas fa-users"></i>
            <span>Usuarios</span>
          </Link>
        </li>
        <li hidden={!userInfo?.isAdmin} className="nav-item">
          <Link to="/admin/companies" className="nav-link">
            <i className="fas fa-fw fa-table"></i>
            <span>Empresas</span>
          </Link>
        </li>
        <li hidden={!userInfo?.isAdmin} className="nav-item">
          <Link to="/access/companies" className="nav-link">
            <i className="fas fa-fw fa-table"></i>
            <span>Gestionar accesos</span>
          </Link>
        </li> */}

      {/* <!-- Divider --> */}
      {/* <hr
          hidden={!userInfo?.isAdmin}
          className="sidebar-divider d-none d-md-block"
        />
 */}
      {/* <!-- Sidebar Toggler (Sidebar) --> */}
      {/* <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
          ></button>
        </div> */}
    </>
  );
};

export default Sidebar;
