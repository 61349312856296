import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Login from "./components/Login/Index";
import Companies from "./components/Companies";
import Dashboards from "./components/Dashboards";
import Users from "./components/Users";
import DashboardInfo from "./components/Dashboards/DashboardInfo";
import UserForm from "./components/Users/Form";
import CompanyAdminList from "./components/Companies/CompanyAdminList";
import CompanyAdminForm from "./components/Companies/CompanyAdminForm";
import DashboardAccess from "./components/DashboardAccess";
import PrivateRoute from "./components/Common/PrivateRoute";
import PageNotFound from "./components/Common/PageNotFound";
import UserContext from "./context/userContext";

const GlobalRouter = () => {
  const { userInfo } = useContext(UserContext);

  return (
    <>
      <Switch>
        {!userInfo ||
          (!userInfo.fullname ? (
            <Route exact path="/login" component={Login} />
          ) : (
            <Layout>
              <Switch>
                {/* Users */}
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/users/new" component={UserForm} />
                <PrivateRoute exact path="/users/edit" component={UserForm} />

                {/* Admin (Companies) */}
                <PrivateRoute
                  exact
                  path="/admin/companies"
                  component={CompanyAdminList}
                />
                <PrivateRoute
                  exact
                  path="/admin/companies/new"
                  component={CompanyAdminForm}
                />
                <PrivateRoute
                  exact
                  path="/admin/companies/edit"
                  component={CompanyAdminForm}
                />
                {/* DashboardsAccess */}
                <PrivateRoute
                  exact
                  path="/access/companies"
                  render={(props) => (
                    <Companies {...props} isAccessRoute title="Accesos" />
                  )}
                />
                <PrivateRoute
                  exact
                  path="/access/companies/dashboards"
                  component={Dashboards}
                />
                <PrivateRoute
                  exact
                  path="/access/companies/dashboards/users"
                  component={DashboardAccess}
                />
                {/* Companies (CARDS) */}
                <PrivateRoute
                  exact
                  path="/companies/dashboards"
                  component={Dashboards}
                />
                <PrivateRoute
                  exact
                  path="/companies/dashboards/info"
                  component={DashboardInfo}
                />
                <PrivateRoute exact path="/companies" component={Companies} />
                <PrivateRoute exact path="/" component={Companies} />
                <Route component={Companies} />
              </Switch>
            </Layout>
          ))}
      </Switch>
    </>
  );
};

export default GlobalRouter;
