import React, { useState } from "react";

const FormInput = ({
  label,
  id,
  name,
  error,
  touched,
  pathSVG,
  ...otherProps
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div
      className={`cont-form icon ${
        otherProps.value ? "focus" : isFocus ? "focus" : ""
      } ${error && touched ? "req" : ""} `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`${otherProps.value ? "focus" : isFocus ? "focus" : ""} ${
            error && touched ? "required" : ""
          }`}
        >
          {label}
        </label>
      )}
      <input
        {...otherProps}
        id={id}
        name={name}
        className={`form-control ${error && touched && "req"} `}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      {error && touched && <div className="form-input-error">{error}</div>}
      {pathSVG && (
        <svg viewBox="0 0 16 16">
          <g>
            <path d={pathSVG} />
          </g>
        </svg>
      )}
    </div>
  );
};

export default FormInput;
