import React, { useEffect, useState } from "react";
import PageHeading from "../Layout/PageHeading";
import Loader from "../Common/Loader";
import logoDefault from "../../assets/img/logo-default.png";

import { getCards, getAllCompaniesToAccess } from "../../services/companies";
const Companies = ({
  history,
  title = "Empresas",
  description = "",
  isAccessRoute,
}) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAccessRoute) {
      getAllCompaniesToAccess().then((r) => {
        setIsLoading(false);
        if (!r) return;
        const { data } = r;
        if (data) setCompanies([...data]);
      });
      return;
    }
    getCards().then((r) => {
      setIsLoading(false);
      if (!r) return;
      const { data } = r;
      if (data) setCompanies([...data]);
    });
  }, [isAccessRoute]);

  const goToDashboards = (companyId, name) => {
    if (isAccessRoute) {
      history.push(`/access/companies/dashboards`, {
        companyId,
        name,
        isAccessRoute,
      });
      return;
    }
    history.push(`/companies/dashboards`, {
      companyId,
      name,
    });
  };

  return (
    <>
      <PageHeading title={title} description={`${description}`} />
      <div className="cards-cont">
        {isLoading && <Loader loading={isLoading} />}

        {companies.map((c, k) => (
          <a
            key={k}
            className="card"
            onClick={() => goToDashboards(c.id, c.name)}
          >
            <div
              style={{
                height: "90px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {c.logo ? (
                <img src={c.logo} className="image" />
              ) : (
                <img src={logoDefault} className="image" />
              )}
            </div>

            <div className="name">{c.name}</div>
            <div className="tag">{`${c.dashboardCount} Tableros`}</div>
          </a>
          /* <Card
            key={c.id}
            title={c.name}
            subtitle={`${c.dashboardCount} Tableros`}
            borderColor="primary"
            onClick={() => goToDashboards(c.id, c.name)}
          /> */
        ))}
      </div>
    </>
  );
};

export default Companies;
