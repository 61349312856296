import React, { useState } from "react";
import { withFormik } from "formik";
import FormInput from "../Common/Inputs/FormInput";
import FormButtons from "../Common/buttons/FormButtons";
import { changePassword } from "../../services/users";
import { showSuccessPopup } from "../../utils/Popups";
import { changePasswordModel } from "./_config";
import Loader from "../Common/Loader";

const ChangePassword = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setTouched,
  isValid,
  initialValues,
  setSubmitting,
  cancelAction,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { oldPassword, newPassword, repeatedPassword } = values;

  const makeSubmit = (e) => {
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        oldPassword: true,
        newPassword: true,
        repeatedPassword: true,
      });
      setSubmitting(false);
    } else {
      setIsLoading(true);
      changePassword(oldPassword, newPassword)
        .then(() => {
          setIsLoading(false);
          responseSuccess();
        })
        .catch(() => setIsLoading(false));
    }
  };

  const responseSuccess = () => {
    showSuccessPopup(
      "Datos guardados",
      "Se han guardado los datos correctamente"
    );
    cancelAction();
  };

  return (
    <>
      <form onSubmit={makeSubmit}>
        <div className="row">
          <div className="col col-lg-12">
            <FormInput
              type="password"
              id="oldPasswordId"
              label="Contraseña actual"
              name="oldPassword"
              value={oldPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.oldPassword}
              touched={touched.oldPassword}
            />
          </div>
          <div className="col col-lg-12">
            <FormInput
              type="password"
              id="newPasswordId"
              label="Nueva contraseña"
              name="newPassword"
              value={newPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.newPassword}
              touched={touched.newPassword}
            />
          </div>
          <div className="col col-lg-12">
            <FormInput
              type="password"
              id="repeatedPasswordId"
              label="Repetir contraseña"
              name="repeatedPassword"
              value={repeatedPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.repeatedPassword}
              touched={touched.repeatedPassword}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <FormButtons cancelAction={cancelAction} />
        )}
      </form>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    oldPassword: "",
    newPassword: "",
    repeatedPassword: "",
  }),
  validationSchema: changePasswordModel,
  displayName: "ChangePassword",
})(ChangePassword);
