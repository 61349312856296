import Swal from "sweetalert2";

export const showSuccessPopup = (title, text) =>
  Swal.fire({
    title,
    text,
    icon: "success",
  });

export const showWarningPopup = (title, text) =>
  Swal.fire({
    title,
    text,
    icon: "warning",
  });
