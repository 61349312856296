import * as Yup from "yup";

export const userModel = Yup.object().shape({
  name: Yup.string().required("El nombre es obligatorio"),
  fatherLastName: Yup.string().required("El apellido paterno es obligatorio"),
  motherLastName: Yup.string().required("El apellido materno es obligatorio"),
  email: Yup.string()
    .email("El correo no cuenta con un formato valido: correo@ejemplo.com")
    .required("El correo es obligatorio"),
  password: Yup.string().required("La contraseña es requerida"),
  roleId: Yup.string().required("El rol es obligatorio"),
});
