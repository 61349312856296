import React, { useState, useEffect } from "react";
import { Modal as ReactstrapModal } from "reactstrap";
import { withFormik } from "formik";
import FormInput from "../Common/Inputs/FormInput";
import { CompanyAdminModel, DashboardAdminModel } from "./_config";
import {
  getAllCompaniesToAccess,
  getCards,
  getCompanyById,
  updateCompany,
  createCompany,
} from "../../services/companies";
import { showSuccessPopup } from "../../utils/Popups";
import SuccessModal from "../Common/containers/SuccessModal";
import DashboardModal from "./DashboardModal";

const CompanyModal = ({
  modalState,
  setModalState,
  company,
  className,
  backdrop = true,
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setValues,
  isValid,
  initialValues,
  setTouched,
  setSubmitting,
  history,
  refresh,
}) => {
  const toggle = () => setModalState(!modalState);
  const [confirmModal, setConfirmModal] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [dashboardInput, setDashboardInput] = useState({ name: "", url: "" });
  const [disabledButton, setDisabledButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { name } = values;
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [viewer, setIsViewer] = useState();

  const addDashboardToTable = () => {
    if (Number.isInteger(dashboardInput.index)) {
      const newArray = [...dashboards];
      newArray[dashboardInput.index] = { ...dashboardInput };
      setDashboards([...newArray]);
    } else setDashboards([...dashboards, { ...dashboardInput }]);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    if (event.target.files.length !== 0) {
      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setIsViewer(objectUrl);
    }

    setIsFilePicked(true);
  };
  useEffect(() => {
    console.log(company);
    if (company && company.id)
      getCompanyById(company.id).then((r) => {
        if (!r) return;
        const { data } = r;
        if (data)
          setValues({
            id: data.id,
            name: data.name,
          });
        if (data && data.dashboards) setDashboards([...data.dashboards]);
        setIsLoading(false);
      });
    else setIsLoading(false);
  }, [company, setValues]);
  const goCompanies = () => history.replace("/admin/companies");
  const deleteDashboard = (index) => {
    const filtered = dashboards.filter((_, i) => i !== index);
    setDashboards([...filtered]);
  };

  const loadModal = (i, id, name, url) => {
    setDashboardInput({ id, name, url, index: i });
    setModal(true);
  };
  useEffect(() => {
    if (dashboardInput.name && dashboardInput.url) setDisabledButton(false);
    else setDisabledButton(true);
  }, [dashboardInput]);

  useEffect(() => {
    if (!modal) {
      setDashboardInput({ name: "", url: "" });
    }
  }, [modal]);

  const makeSubmit = (e) => {
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        name: true,
      });
      setSubmitting(false);
    } else {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("name", values.name);
      for (let dashb of dashboards) {
        formData.append("dashboards", JSON.stringify(dashb));
      }

      if (isFilePicked) {
        formData.append("logo", selectedFile);
      } else {
        formData.append("logo", null);
      }
      if (values && values.id) {
        formData.append("id", values.id);
        updateCompany(formData, config).then((r) => {
          if (!r) return;
          const { data } = r;
          if (data) setConfirmModal(true);
        });
        return;
      }

      createCompany(formData, config).then((r) => {
        if (!r) return;
        const { data } = r;
        if (data) {
          setConfirmModal(true);
        }
      });
    }
  };
  return (
    <ReactstrapModal
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <div id="usernew" className="popup-cont medium">
        <form onSubmit={makeSubmit}>
          <div className="modal">
            <h3>{company && company.id ? "Editar" : "Nueva"} empresa</h3>
            <div className="logo-company add-logo">
              <label>
                {isFilePicked ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    style={{ width: "80px" }}
                  />
                ) : (
                  <>
                    {company && company.logo ? (
                      <img src={company.logo} style={{ width: "80px" }} />
                    ) : (
                      <>
                        {!isFilePicked && (
                          <>
                            <input
                              type="file"
                              name="file"
                              onChange={changeHandler}
                              accept="image/*"
                            />
                            <svg viewBox="0 0 16 16" style={{ width: "80px" }}>
                              <g>
                                <path d="M14,1c0.6,0,1.1,0.2,1.4,0.6C15.8,2,16,2.4,16,3v10c0,0.6-0.2,1-0.6,1.4C15,14.8,14.6,15,14,15H2c-0.5,0-1-0.2-1.4-0.6 C0.2,14,0,13.6,0,13V3c0-0.6,0.2-1,0.6-1.4S1.4,1,2,1H14z M15,13V3c0-0.3-0.1-0.5-0.3-0.7S14.3,2,14,2H2C1.7,2,1.5,2.1,1.3,2.3 S1,2.7,1,3v10c0,0.3,0.1,0.5,0.3,0.7S1.7,14,2,14h12c0.3,0,0.5-0.1,0.7-0.3S15,13.3,15,13z M10.2,4.9l3.7,5.6c0.2,0.3,0.2,0.6,0,1 c-0.2,0.3-0.5,0.5-0.8,0.5H3c-0.4,0-0.7-0.2-0.9-0.5c-0.1-0.4-0.1-0.7,0.1-1l2-2.8C4.4,7.4,4.7,7.3,5,7.3c0.3,0,0.6,0.1,0.8,0.4 l0.5,0.7l2.3-3.5C8.8,4.7,9,4.5,9.4,4.5C9.7,4.5,10,4.7,10.2,4.9z M4.5,6C4.1,6,3.7,5.8,3.4,5.6C3.2,5.3,3,4.9,3,4.5 c0-0.4,0.2-0.8,0.4-1.1C3.7,3.2,4.1,3,4.5,3c0.4,0,0.8,0.2,1.1,0.4C5.8,3.7,6,4.1,6,4.5c0,0.4-0.2,0.8-0.4,1.1C5.3,5.8,4.9,6,4.5,6 z M3,11h10L9.3,5.5l-2.6,4C6.6,9.7,6.5,9.8,6.3,9.8C6.1,9.8,6,9.7,5.9,9.6L4.9,8.3L3,11z M4.5,4C4.2,4,4,4.2,4,4.5 C4,4.8,4.2,5,4.5,5C4.8,5,5,4.8,5,4.5C5,4.2,4.8,4,4.5,4z" />{" "}
                              </g>
                            </svg>
                            <div>Cargar logo</div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {company && company.logo && (
                  <>
                    <input
                      type="file"
                      name="file"
                      onChange={changeHandler}
                      accept="image/*"
                    />

                    <div>Editar</div>
                  </>
                )}
              </label>
            </div>
            <FormInput
              type="text"
              id="nameId"
              name="name"
              label="Nombre"
              value={name}
              error={errors.name}
              touched={touched.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div className="cont-form">
              <button
                className="btn outline icon full newboard"
                type="button"
                onClick={() => setModal(true)}
              >
                <svg viewBox="0 0 16 16">
                  <g>
                    <path d="M15.1,8.6H8.6v6.5c0,0.4-0.2,0.6-0.6,0.6c-0.4,0-0.6-0.2-0.6-0.6V8.6H0.9C0.5,8.6,0.3,8.4,0.3,8c0-0.4,0.2-0.6,0.6-0.6h6.5 V0.9c0-0.4,0.2-0.6,0.6-0.6c0.4,0,0.6,0.2,0.6,0.6v6.5h6.5c0.4,0,0.6,0.2,0.6,0.6C15.7,8.4,15.5,8.6,15.1,8.6z" />{" "}
                  </g>
                </svg>
                Agregar tablero
              </button>
            </div>
            <div
              className="cards-cont tiny"
              style={{ justifyContent: "center" }}
            >
              {dashboards.map((u, i) => (
                <div key={i} className="card tablero">
                  <div className="name">{u.name}</div>
                  <div className="actions-cont tiny right">
                    <button
                      className="newboard"
                      type="button"
                      onClick={() => loadModal(i, u.id, u.name, u.url)}
                    >
                      <svg viewBox="0 0 16 16">
                        <g>
                          <path d="M15.5,3.4c0,0.5-0.2,0.8-0.5,1.2l-9.2,9.2c-0.3,0.3-0.7,0.6-1.2,0.7l-3.6,1c-0.2,0.1-0.4,0-0.5-0.1 c-0.1-0.1-0.2-0.3-0.1-0.4l1-3.6c0.1-0.5,0.4-0.9,0.7-1.2l9.2-9.2c0.3-0.3,0.7-0.5,1.2-0.5c0.4,0,0.8,0.2,1.2,0.5l1.3,1.3 C15.4,2.5,15.5,2.9,15.5,3.4z M2.9,10.8c-0.2,0.2-0.4,0.5-0.5,0.8l-0.8,2.7l2.7-0.8c0.3-0.1,0.6-0.3,0.8-0.5l6.9-6.9L9.8,3.9 L2.9,10.8z M12.8,5.4l1.6-1.6c0.3-0.3,0.3-0.7,0-1l-1.2-1.2c-0.3-0.3-0.7-0.3-1,0l-1.6,1.6L12.8,5.4z" />{" "}
                        </g>
                      </svg>
                      <div className="tooltip">Editar</div>
                    </button>
                    <button type="button" onClick={() => deleteDashboard(i)}>
                      <svg viewBox="0 0 16 16">
                        <g>
                          <path d="M11.1,2.8h2.6c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-0.4v9.1c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6H5 c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V3.7H2.4C2.1,3.7,2,3.5,1.9,3.2C2,3,2.1,2.8,2.4,2.8h2.6l0.7-1.1 c0.3-0.4,0.6-0.6,1.1-0.6h2.5c0.5,0,0.8,0.2,1.1,0.6L11.1,2.8z M4.1,13.7C4.3,13.9,4.6,14,5,14.1H11c0.4,0,0.7-0.1,0.9-0.4 c0.2-0.3,0.4-0.6,0.4-0.9V3.7H3.7v9.1C3.7,13.1,3.8,13.4,4.1,13.7z M5.4,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4 c0.3,0,0.4,0.2,0.4,0.4v6.1C5.8,12.2,5.7,12.3,5.4,12.3z M10,2.8L9.6,2.2C9.5,2,9.4,1.9,9.2,1.9H6.8C6.6,1.9,6.5,2,6.4,2.2L6,2.8 H10z M8,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C8.4,12.2,8.3,12.3,8,12.3z M10.6,12.3 c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C11,12.2,10.9,12.3,10.6,12.3z" />{" "}
                        </g>
                      </svg>
                      <div className="tooltip">Eliminar</div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="buttons-cont">
              <div className="btn secondary close-popup" onClick={toggle}>
                Cancelar
              </div>
              <button type="submit" className="btn close-popup">
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
      <SuccessModal
        modalState={confirmModal}
        setModalState={setConfirmModal}
        text={`Empresa ${values.id ? "actualizada" : "Creado"} correctamente`}
        okAction={() => {
          refresh();
          setConfirmModal(false);
          setModalState(false);
        }}
      />
      <DashboardModal
        modalState={modal}
        setModalState={setModal}
        dashboardInput={dashboardInput}
        setDashboardInput={setDashboardInput}
        okAction={addDashboardToTable}
      />
    </ReactstrapModal>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    id: "",
    name: "",
  }),
  validationSchema: CompanyAdminModel,
  displayName: "CompanyAdminForm",
})(CompanyModal);
