import React, { useEffect, useState } from "react";
import PageHeading from "../Layout/PageHeading";
import SelectInput from "../Common/Inputs/SelectInput";
import ReactTooltip from "react-tooltip";
import {
  addAccessToDashboard,
  getAccessUsers,
  getUsersWithNoAccess,
  removeAccessToDashboard,
} from "../../services/dashboards";
import { showSuccessPopup } from "../../utils/Popups";
import Loader from "../Common/Loader";

const DashboardAccess = ({ location, history }) => {
  const [selected, setSelected] = useState("");
  const [users, setUsers] = useState([]);
  const [tableUsers, setTableUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    console.log(location.state);
    if (!location.state) {
      history.replace("/companies");
      return;
    }
    getUsersWithNoAccess(location.state.dashboardId).then((r) => {
      if (!r) return;
      const { data } = r;
      if (data) {
        const mapped = data.map((x) => ({
          id: x.id,
          name: x.fullName,
        }));
        setUsers([...mapped]);
      }
    });

    getAccessUsers(location.state.dashboardId).then((r) => {
      setIsLoading(false);
      if (!r) return;
      const { data } = r;
      if (data) {
        const mapped = data.map((x) => ({
          id: x.id,
          name: x.fullName,
        }));
        setTableUsers([...mapped]);
      } else setTableUsers([]);
    });
  };

  const addAccessToUser = () => {
    const foundItem = users.find((x) => x.id === selected);
    addAccessToDashboard(location.state.dashboardId, selected).then(() => {
      setTableUsers([...tableUsers, { ...foundItem }]);
      setUsers([...users.filter((x) => x.id !== foundItem.id)]);
      setSelected("");
      showSuccessPopup(
        "Acceso Asignado",
        "Se han guardado los datos correctamente"
      );
    });
  };

  const removeAccessToUser = (userId) => {
    removeAccessToDashboard(location.state.dashboardId, userId).then((r) => {
      if (!r) return;
      const { data } = r;
      if (data) {
        getData();
        showSuccessPopup(
          "Acceso Removido",
          "Se ha removido el acceso correctamente"
        );
      }
    });
  };
  return (
    <>
      {/* <Companies title="Accesos" history={history} isAccessRoute={true} /> */}
      <PageHeading title="Accesos" description="" />
      <div class="table-head">
        <div class="col-filters">
          <SelectInput
            id="userId"
            name="user"
            label=""
            value={selected}
            data={users}
            placeholder="Seleccione rol"
            onChange={(e) => {
              setSelected(e.currentTarget.value);
            }}
          />
          <button
            class="btn outline icon"
            disabled={!!!selected}
            onClick={addAccessToUser}
            data-tip="Agregar usuario"
          >
            <svg viewBox="0 0 16 16">
              <g>
                <path d="M9.9,14.7c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1c-0.2-0.3-0.2-0.5,0-0.8l5-5.3H0.6C0.2,8.5,0,8.3,0,8 c0-0.4,0.2-0.6,0.6-0.6h13.5L9,2.1c-0.2-0.3-0.2-0.6,0-0.8c0.3-0.2,0.6-0.2,0.9,0l5.9,6.3c0.2,0.3,0.2,0.5,0,0.8L9.9,14.7z" />{" "}
              </g>
            </svg>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3"></div>
            <div className="card-body">
              <div className="table-cont">
                <Loader loading={isLoading} />

                <table
                  className="table table-bordered"
                  id="example"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableUsers.map((u, i) => (
                      <tr key={i}>
                        <td>{u.name}</td>
                        <td>
                          <div class="actions-cont">
                            <button
                              class="delete-user"
                              onClick={() => removeAccessToUser(u.id)}
                            >
                              <svg viewBox="0 0 16 16">
                                <g>
                                  <path d="M11.1,2.8h2.6c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-0.4v9.1c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6H5 c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V3.7H2.4C2.1,3.7,2,3.5,1.9,3.2C2,3,2.1,2.8,2.4,2.8h2.6l0.7-1.1 c0.3-0.4,0.6-0.6,1.1-0.6h2.5c0.5,0,0.8,0.2,1.1,0.6L11.1,2.8z M4.1,13.7C4.3,13.9,4.6,14,5,14.1H11c0.4,0,0.7-0.1,0.9-0.4 c0.2-0.3,0.4-0.6,0.4-0.9V3.7H3.7v9.1C3.7,13.1,3.8,13.4,4.1,13.7z M5.4,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4 c0.3,0,0.4,0.2,0.4,0.4v6.1C5.8,12.2,5.7,12.3,5.4,12.3z M10,2.8L9.6,2.2C9.5,2,9.4,1.9,9.2,1.9H6.8C6.6,1.9,6.5,2,6.4,2.2L6,2.8 H10z M8,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C8.4,12.2,8.3,12.3,8,12.3z M10.6,12.3 c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C11,12.2,10.9,12.3,10.6,12.3z" />{" "}
                                </g>
                              </svg>
                              <div class="tooltip">Eliminar</div>
                            </button>
                          </div>
                        </td>
                        {/* <td>
                          <>
                            <ReactTooltip
                              effect="solid"
                              id="deleteButtonId"
                              type="dark"
                            >
                              <span>Quitar acceso</span>
                            </ReactTooltip>
                            <span
                              onClick={() => removeAccessToUser(u.id)}
                              className="margin-10 c-pointer"
                            >
                              <i
                                data-tip
                                data-for="deleteButtonId"
                                className="fas fa-times"
                              ></i>
                            </span>
                          </>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAccess;
