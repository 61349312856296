import http from "./http";

const endpoint = "dashboards";
export const getDashboardsByCompany = (id) =>
  http.get(`${endpoint}/GetByCompanyID/${id}`);
export const getAccessUsers = (dashboardId) =>
  http.get(`${endpoint}/GetUsers/${dashboardId}`);
export const getUsersWithNoAccess = (dashboardId) =>
  http.get(`${endpoint}/GetUsersWithNoAccess/${dashboardId}`);
export const addAccessToDashboard = (dashboardId, userId) =>
  http.put(`${endpoint}/AddAccessPermission`, { dashboardId, userId });
export const removeAccessToDashboard = (dashboardId, userId) =>
  http.put(`${endpoint}/RemoveAccessPermission`, { dashboardId, userId });
export const getAllDashboardsByCompany = (id) =>
  http.get(`${endpoint}/access/GetByCompanyID/${id}`);
