/* eslint-disable no-undef */
const dev = {
  apiUrl: "http://localhost:8080/",
};

const prod = {
  apiUrl: "https://biwatchapi.ucombi.com/",
};

const config = process.env.REACT_APP_ENV === "dev" ? dev : prod;

export default {
  ...config,
  defaultColor: "#4e73df",
};
