import React, { useEffect } from "react";
import "./styles.css";

const DashboardInfo = ({ location, history }) => {
  return (
    <>
      {location && location.state ? (
        <iframe
          className="height-bi"
          width="100%"
          title={location.state.id}
          src={`${location.state.biUrl}`}
          frameBorder="0"
          allowfullscreen="true"
        ></iframe>
      ) : (
        history.replace("/companies")
      )}
    </>
  );
};

export default DashboardInfo;
