import http from "./http";

const endpoint = "users";
export const getRoles = () => http.get("roles/GetAll?isActive=true");
export const getUsers = (isActive, searchTerm) =>
  http.get(
    `${endpoint}/GetAll?${
      isActive !== undefined
        ? isActive === ""
          ? ""
          : "isActive=" + isActive
        : ""
    }&searchTerm=${searchTerm !== undefined ? searchTerm : ""}`
  );
export const getUserById = (id) => http.get(`${endpoint}/GetByID/${id}`);
export const createUser = (user) =>
  http.post(`${endpoint}/Create`, JSON.stringify(user));
export const updateUser = (user) =>
  http.put(`${endpoint}/Update`, JSON.stringify(user));
export const changeStatus = (id) => http.put(`${endpoint}/ChangeStatus/${id}`);
export const changePassword = (oldPassword, newPassword) =>
  http.put(`${endpoint}/change_password`, { oldPassword, newPassword });
