import http from "./http";
import jwt_decode from "jwt-decode";
const endpoint = "auth";
const tokenKey = "JAD_TOKEN";
export const SignIn = (username, password) => {
  return http.post(`${endpoint}/Login`, { email: username, password });
};

export const saveToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

export const getToken = () => {
  return localStorage.getItem(tokenKey);
};

export const getUserInfo = () => {
  const token = getToken();
  if (!token) return null;
  const { email, fullname, is_admin } = jwt_decode(token);
  return { email, fullname, isAdmin: is_admin };
};

export const logout = () => {
  localStorage.removeItem(tokenKey);
};
