import React, { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { getUserInfo, logout } from "../../services/auth";
import CustomModal from "../Common/containers/CustomModal";
import ChangePassword from "../UserProfile/ChangePassword";
import UserContext from "../../context/userContext";
import { RouteLink } from "../../models/RouteLink";
const Header = (routes) => {
  const [modal, setModal] = useState(false);
  const { userInfo, clearUser } = useContext(UserContext);

  const loadModal = () => {
    setModal(true);
  };

  const signout = () => {
    clearUser();
    logout();
  };

  return (
    <header className="space">
      <div className="breadcrumb">
        {/* <a href="accesos.html">Accesos</a>
        <svg viewBox="0 0 16 16">
          <g>
            <path d="M4.1,15.2c0-0.1,0-0.3,0.1-0.4L10.5,8L4.3,1.2c-0.2-0.3-0.2-0.5,0-0.8c0.3-0.2,0.5-0.2,0.8,0l6.7,7.2 c0.2,0.3,0.2,0.5,0,0.8l-6.7,7.2c-0.3,0.2-0.6,0.2-0.8,0C4.2,15.5,4.1,15.4,4.1,15.2z" />
          </g>
        </svg>
        <a href="accesos-tableros.html">Red benefit</a>
        <svg viewBox="0 0 16 16">
          <g>
            <path d="M4.1,15.2c0-0.1,0-0.3,0.1-0.4L10.5,8L4.3,1.2c-0.2-0.3-0.2-0.5,0-0.8c0.3-0.2,0.5-0.2,0.8,0l6.7,7.2 c0.2,0.3,0.2,0.5,0,0.8l-6.7,7.2c-0.3,0.2-0.6,0.2-0.8,0C4.2,15.5,4.1,15.4,4.1,15.2z" />
          </g>
        </svg>
        <h2>Incapacidades Generales</h2> */}
      </div>
      <div className="user-name">
        <svg viewBox="0 0 16 16" className="icon">
          <g>
            <path d="M8,0.2c1.5,0,2.8,0.4,3.9,1.1c1.2,0.7,2.1,1.6,2.8,2.8c0.7,1.2,1.1,2.5,1.1,3.9c0,1.5-0.4,2.8-1.1,3.9 c-0.7,1.2-1.6,2.1-2.8,2.8c-1.2,0.7-2.5,1.1-3.9,1.1c-1.5,0-2.8-0.4-3.9-1.1c-1.2-0.7-2.1-1.6-2.8-2.8C0.5,10.8,0.2,9.5,0.2,8 c0-1.5,0.4-2.8,1.1-3.9c0.7-1.2,1.6-2.1,2.8-2.8C5.2,0.5,6.5,0.2,8,0.2z M12.8,12.9c0.7-0.7,1.2-1.4,1.5-2.2 C14.7,9.9,14.9,9,14.9,8c0-1.3-0.3-2.4-0.9-3.5c-0.6-1-1.4-1.8-2.4-2.4c-1-0.6-2.2-0.9-3.5-0.9c-1.3,0-2.4,0.3-3.5,0.9 c-1,0.6-1.8,1.4-2.4,2.4C1.5,5.6,1.2,6.7,1.1,8c0.1,2,0.8,3.6,2.1,4.9c0.2-0.9,0.7-1.6,1.4-2.1C5.3,10.3,6.1,10,7,10h2 c0.9,0,1.7,0.3,2.4,0.9C12.1,11.3,12.5,12,12.8,12.9z M8,14.9c1.5,0,2.8-0.4,3.9-1.2c-0.1-0.8-0.4-1.4-0.9-1.9 c-0.5-0.5-1.2-0.8-2-0.8H7c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.1-0.9,1.9C5.2,14.4,6.5,14.8,8,14.9z M8,4.1c0.7,0,1.3,0.3,1.7,0.7 c0.4,0.5,0.7,1.1,0.7,1.7c0,0.7-0.3,1.3-0.7,1.7C9.3,8.7,8.7,9,8,9C7.3,9,6.7,8.7,6.3,8.3C5.8,7.8,5.6,7.2,5.6,6.5 c0-0.7,0.3-1.3,0.7-1.7C6.7,4.3,7.3,4.1,8,4.1z M8,8c0.4,0,0.8-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.2-0.8-0.4-1 C8.8,5.2,8.4,5.1,8,5.1c-0.4,0-0.8,0.2-1,0.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.2,0.8,0.4,1C7.2,7.8,7.6,8,8,8z" />{" "}
          </g>
        </svg>
        {userInfo && userInfo.fullname}
        <svg viewBox="0 0 16 16" className="arrow">
          <g>
            <path d="M0.8,4.1c0.1,0,0.3,0,0.4,0.1L8,10.5l6.8-6.3c0.3-0.2,0.5-0.2,0.8,0c0.2,0.3,0.2,0.5,0,0.8l-7.2,6.7 c-0.3,0.2-0.5,0.2-0.8,0L0.4,5.1c-0.2-0.3-0.2-0.6,0-0.8C0.5,4.2,0.6,4.1,0.8,4.1z" />{" "}
          </g>
        </svg>
        <div className="submenu">
          <a id="change-pass" onClick={loadModal}>
            <svg viewBox="0 0 16 16">
              <g>
                <path d="M15,8.5v6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-11c-0.4,0-0.8-0.1-1.1-0.4C1.2,15.3,1,14.9,1,14.5v-6c0-0.4,0.1-0.8,0.4-1.1C1.7,7.1,2.1,7,2.5,7H3V5c0-1.4,0.5-2.5,1.5-3.5C5.5,0.5,6.6,0,8,0c1.4,0,2.6,0.5,3.5,1.5S13,3.7,13,5.1V7h0.5c0.4,0,0.8,0.1,1.1,0.4S15,8.1,15,8.5z M14,14.5v-6c0-0.1,0-0.3-0.1-0.4S13.6,8,13.5,8h-11C2.4,8,2.2,8,2.1,8.1C2.1,8.2,2,8.4,2,8.5v6c0,0.1,0,0.3,0.1,0.4C2.2,14.9,2.4,15,2.5,15h11c0.1,0,0.3,0,0.4-0.1S14,14.6,14,14.5zM4,7h8V5c0-1.1-0.4-2-1.2-2.8S9.1,1,8,1C6.9,1,6,1.4,5.2,2.2S4,3.9,4,5V7z M8.4,12.9c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4v-2c0-0.2,0.1-0.3,0.2-0.4C7.7,9.9,7.8,9.9,8,9.9c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4v2C8.6,12.7,8.6,12.8,8.4,12.9z" />
              </g>
            </svg>
            Cambiar contraseña
          </a>
          <a onClick={signout}>
            <svg viewBox="0 0 16 16">
              <g>
                <path d="M5.6,13.9c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H2.6c-0.7,0-1.3-0.3-1.7-0.7c-0.4-0.5-0.7-1.1-0.7-1.7V3.6 c0-0.7,0.3-1.3,0.7-1.7c0.5-0.4,1.1-0.7,1.7-0.7h2.9C5.9,1.2,6,1.3,6,1.6c0,0.3-0.2,0.5-0.5,0.5H2.6c-0.4,0-0.8,0.2-1,0.4 c-0.3,0.3-0.4,0.6-0.4,1v8.8c0,0.4,0.2,0.8,0.4,1c0.3,0.3,0.6,0.4,1,0.4H5.6z M15.7,7.7c0.1,0.1,0.2,0.2,0.2,0.3s-0.1,0.2-0.2,0.3 l-4.4,4.4c-0.2,0.2-0.4,0.2-0.7,0c-0.2-0.2-0.2-0.4,0-0.7l3.5-3.6H5.6C5.2,8.5,5.1,8.3,5.1,8c0-0.3,0.2-0.5,0.5-0.5h8.6l-3.5-3.6 c-0.2-0.2-0.2-0.4,0-0.7c0.2-0.2,0.4-0.2,0.7,0L15.7,7.7z" />
              </g>
            </svg>
            Cerrar sesión
          </a>
        </div>
      </div>
    </header>
    /*         <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <span
              className="nav-link dropdown-toggle"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {userInfo && userInfo.fullname}
              </span>
              <Avatar
                name={userInfo?.fullname}
                maxInitials={2}
                size="45"
                round="90"
                color="#4e73df"
              />
            </span>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <span className="dropdown-item c-pointer" onClick={loadModal}>
                <i className="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
                Cambiar contraseña
              </span>
              <span className="dropdown-item c-pointer">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Settings
              </span>
              <span className="dropdown-item c-pointer">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
              </span> 
              <div className="dropdown-divider"></div>
              <Link
                to="/login"
                onClick={signout}
                className="dropdown-item c-pointer"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cerrar sesión
              </Link>
            </div>
          </li>
        </ul>
      </nav>  */
    /* <CustomModal
        modalState={modal}
        setModalState={setModal}
        disableFooter={true}
        title="Cambiar contraseña"
        size="lg"
        backdrop="static"
      >
        <ChangePassword
          cancelAction={() => {
            setModal(false);
          }}
        />
      </CustomModal> */
  );
};

export default Header;
