import React from "react";
import {
  Button,
  Modal as ReactstrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const CustomModal = ({
  modalState,
  setModalState,
  title,
  className,
  OkAction,
  size,
  disabled,
  backdrop = true,
  disableFooter,
  children,
}) => {
  const toggle = () => setModalState(!modalState);
  return (
    <ReactstrapModal
      size={size}
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {!!!disableFooter && (
        <ModalFooter>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => {
              OkAction();
              toggle();
            }}
          >
            Aceptar
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      )}
    </ReactstrapModal>
  );
};

export default CustomModal;
