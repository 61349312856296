import http from "./http";

const endpoint = "companies";
export const getCards = () => http.get(`${endpoint}/GetCards`);
export const getAllCompaniesToAccess = (isActive, searchTerm) =>
  http.get(
    `${endpoint}/GetAll?${
      isActive !== undefined
        ? isActive === ""
          ? ""
          : "isActive=" + isActive
        : ""
    }${searchTerm !== undefined ? "&searchTerm=" + searchTerm : ""}`
  );
export const getCompanyById = (id) => http.get(`${endpoint}/GetByID/${id}`);
export const createCompany = (company, config) =>
  http.post(`${endpoint}/Create`, company, config);
export const updateCompany = (company, config) =>
  http.put(`${endpoint}/Update`, company, config);
export const deleteCompany = (id) => http.delete(`${endpoint}/Delete/${id}`);
export const changeStatus = (id) => http.put(`${endpoint}/ChangeStatus/${id}`);
