import axios from "axios";
import config from "../config";
import { showWarningPopup } from "../utils/Popups";
const tokenKey = "JAD_TOKEN";

const http = axios.create({
  baseURL: config.apiUrl,
});
http.interceptors.request.use(function (config) {
  const token = localStorage.getItem("JAD_TOKEN");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

http.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem(tokenKey);
    showWarningPopup("¡Acceso denegado!", getError(error));
    return;
  }

  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    showWarningPopup("Hubo un problema...", getError(error));
  } else {
    showWarningPopup(
      "¡Algo salió mal!",
      "Se esta trabajando para solucionar estos problemas. Intente de nuevo mas tarde"
    );
  }
  return Promise.reject(error);
});

const getError = (error) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error;
  }
  return "Asegurese de llenar los datos correctamente";
};
export default http;
