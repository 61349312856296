import "./App.css";
import { BrowserRouter, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import GlobalRouter from "./Router";
import { getToken, getUserInfo } from "./services/auth";
import UserContext from "./context/userContext";

function App() {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      //document.body.classList.add("bg-gradient-primary");
    } else {
      //document.body.classList.remove("bg-gradient-primary");
    }
  }, []);

  useEffect(() => {
    if (!userInfo) getCurrentUser();
  }, [userInfo]);

  const getCurrentUser = () => {
    const user = getUserInfo();
    setUserInfo({ ...user });
  };
  const clearCurrentUser = () => {
    setUserInfo(null);
  };
  return (
    <div className="app">
      <BrowserRouter>
        <UserContext.Provider
          value={{
            userInfo,
            setUserInfo: getCurrentUser,
            clearUser: clearCurrentUser,
          }}
        >
          <GlobalRouter />
          {!getToken() && <Redirect to="/login" />}
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
