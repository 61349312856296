import React from "react";

const FormButtons = ({ cancelAction }) => {
  return (
    <>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col col-lg-2">
          <button type="submit" className="btn btn-lg btn-success">
            Guardar
          </button>
        </div>
        <div className="col col-lg-2">
          <button
            onClick={cancelAction}
            type="button"
            className="btn btn-lg btn-danger"
          >
            Cancelar
          </button>
        </div>
      </div>
    </>
  );
};

export default FormButtons;
