import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { userModel } from "./_config";
import {
  createUser,
  getRoles,
  getUserById,
  updateUser,
} from "../../services/users";
import PageHeading from "../Layout/PageHeading";
import FormInput from "../Common/Inputs/FormInput";
import SelectInput from "../Common/Inputs/SelectInput";
import FormButtons from "../Common/buttons/FormButtons";
import { showSuccessPopup } from "../../utils/Popups";
import Loader from "../Common/Loader";

const UserForm = ({
  history,
  location,
  values,
  setValues,
  errors,
  isValid,
  touched,
  setTouched,
  setSubmitting,
  handleChange,
  handleBlur,
  initialValues,
}) => {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getRoles().then((r) => {
      if (r.data) {
        setRoles([...r.data]);
      }
    });

    if (location.state && location.state.id) {
      getUserById(location.state.id).then((r) => {
        if (!r) return;

        if (r.data) {
          const { data } = r;
          setValues({
            id: data.id,
            name: data.name,
            fatherLastName: data.fatherLastName,
            motherLastName: data.motherLastName,
            email: data.email,
            roleId: data.roleId,
            password: data.password || "",
          });
          setIsLoading(false);
        }
      });
    } else setIsLoading(false);
  }, []);

  const goUsers = () => history.replace("/users");

  const responseSuccess = () => {
    showSuccessPopup(
      "Datos guardados",
      "Se han guardado los datos correctamente"
    );
    goUsers();
  };

  const makeSubmit = (e) => {
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        name: true,
        fatherLastName: true,
        motherLastName: true,
        email: true,
        password: true,
        roleId: true,
      });
      setSubmitting(false);
    } else {
      if (values.id)
        updateUser(values).then(() => {
          responseSuccess();
        });
      else
        createUser(values).then(() => {
          responseSuccess();
        });
    }
  };

  const {
    name,
    fatherLastName,
    motherLastName,
    email,
    password,
    roleId,
  } = values;
  return (
    <>
      <PageHeading
        title={`${
          location.state && location.state.id ? "Actualizar" : "Crear"
        } usuario`}
        description={`${
          location.state && location.state.id ? "Actualiza" : "Agrega"
        } un usuario que utilizará la plataforma`}
      />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <form onSubmit={makeSubmit}>
          <FormInput
            id="nameId"
            name="name"
            label="Nombre"
            value={name}
            placeholder="Ejemplo: José Luis"
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormInput
            id="fatherLastNameId"
            name="fatherLastName"
            label="Apellido paterno"
            value={fatherLastName}
            placeholder="Ejemplo: López"
            error={errors.fatherLastName}
            touched={touched.fatherLastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormInput
            id="motherLastNameId"
            name="motherLastName"
            label="Apellido materno"
            value={motherLastName}
            placeholder="Ejemplo: Castro"
            error={errors.motherLastName}
            touched={touched.motherLastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormInput
            type="email"
            id="emailId"
            name="email"
            label="Email"
            value={email}
            placeholder="Ejemplo: ejemplo@empresa.com"
            error={errors.email}
            touched={touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormInput
            type="password"
            id="passwordId"
            name="password"
            label="Contraseña"
            value={password}
            error={errors.password}
            touched={touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <SelectInput
            id="roleUnique"
            name="roleId"
            label="Rol"
            value={roleId}
            data={roles}
            placeholder="Seleccione rol"
            error={errors.roleId}
            touched={touched.roleId}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormButtons cancelAction={goUsers} />
        </form>
      )}
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    id: "",
    name: "",
    fatherLastName: "",
    motherLastName: "",
    email: "",
    password: "",
    roleId: "",
  }),
  validationSchema: userModel,
  displayName: "UserForm",
})(UserForm);
