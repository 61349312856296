import React, { useEffect, useState } from "react";
import { changeStatus, getUsers } from "../../services/users";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PageHeading from "../Layout/PageHeading";
import * as moment from "moment";
import "./styles.css";
import ChangeStatus from "../Common/components/ChangeStatus";
import Swal from "sweetalert2";
import { showSuccessPopup } from "../../utils/Popups";
import Loader from "../Common/Loader";
import { Modal } from "reactstrap";
import CustomModal from "../Common/containers/CustomModal";
import ConfirmModal from "../Common/containers/ConfirmModal";
import FormInput from "../Common/Inputs/FormInput";
import UserModal from "../Common/containers/UserModal";
import SuccessModal from "../Common/containers/SuccessModal";

const Users = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getUserByStatus();
  }, [isActive, searchTerm]);

  const getUserByStatus = () => {
    getUsers(isActive, searchTerm).then((r) => {
      if (!r) return;

      if (r.data) setUsers([...r.data]);
      else setUsers([]);
      setIsLoading(false);
    });
  };
  return (
    <>
      <ReactTooltip />

      <PageHeading title="Usuarios" description="" />
      <div className="table-head">
        <div className="col-filters">
          <div className="search-cont">
            <input
              type="text"
              placeholder="Buscar usuario"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <svg viewBox="0 0 16 16">
              <g>
                <path d="M15.6,15c0.2,0.2,0.2,0.4,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2-0.1-0.4-0.2l-4.3-4.3c-1.1,1-2.5,1.5-4.1,1.5 c-1.8,0-3.3-0.7-4.4-1.9C0.9,9.8,0.3,8.3,0.2,6.5c0-1.8,0.6-3.3,1.8-4.5c1.2-1.2,2.7-1.8,4.5-1.9c1.8,0,3.3,0.7,4.5,1.9 s1.8,2.7,1.9,4.5c0,1.6-0.5,3-1.5,4.1L15.6,15z M6.5,11.9c1.5,0,2.8-0.6,3.8-1.5c1-1,1.5-2.3,1.6-3.8c0-1.5-0.6-2.8-1.5-3.8 c-1-1-2.3-1.5-3.8-1.5C5,1.2,3.8,1.8,2.7,2.8c-1,1-1.5,2.2-1.5,3.8c0,1.5,0.6,2.8,1.5,3.8C3.8,11.3,5,11.8,6.5,11.9z" />{" "}
              </g>
            </svg>
          </div>
          <div className="checks-cont">
            <label>
              <input
                type="radio"
                name="active"
                checked={isActive === ""}
                onClick={() => setIsActive("")}
              />
              <div>Todos</div>
            </label>
            <label onClick={() => setIsActive(true)}>
              <input type="radio" name="active" checked={isActive} />
              <div>Activos</div>
            </label>
            <label onClick={() => setIsActive(false)}>
              <input
                type="radio"
                name="active"
                checked={!isActive && isActive !== ""}
              />
              <div>Inactivos</div>
            </label>
          </div>
        </div>

        <button
          className="btn icon secondary open-user"
          onClick={() => setOpenNewModal(true)}
        >
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M6.9,9.2c1.2,0,2.2,0.5,3.1,1.3s1.2,1.8,1.3,3.1c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3H0.9 c-0.3,0-0.5-0.1-0.6-0.3C0.1,14,0,13.8,0,13.5c0-1.2,0.5-2.2,1.3-3.1s1.8-1.2,3.1-1.3H6.9z M10.3,13.6 C10.4,13.6,10.4,13.6,10.3,13.6c0-1.1-0.3-1.9-1-2.6c-0.7-0.7-1.5-1-2.5-1H4.3c-1,0-1.8,0.4-2.5,1c-0.7,0.7-1,1.5-1,2.5 c0,0.1,0,0.1,0.1,0.1H10.3z M5.6,8C4.7,8,3.9,7.7,3.3,7.1C2.7,6.5,2.4,5.7,2.4,4.8c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9 c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3C7.3,7.7,6.5,8,5.6,8z M5.6,2.4c-0.7,0-1.3,0.3-1.7,0.7 s-0.7,1-0.7,1.7c0,0.7,0.3,1.3,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.3,1.7-0.7C7.7,6,8,5.5,8,4.8c0-0.7-0.3-1.3-0.7-1.7 S6.3,2.4,5.6,2.4z M15.6,6.8c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4H14v1.6c0,0.3-0.2,0.4-0.4,0.4c-0.3,0-0.4-0.2-0.4-0.4 V7.6h-1.6c-0.3,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.4,0.4-0.4h1.6V5.2c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v1.6H15.6z" />{" "}
            </g>
          </svg>
          Nuevo usuario
        </button>
      </div>

      <div className="table-cont">
        <table cellSpacing="0">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Creado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u, i) => (
              <tr key={i}>
                <td>{u.fullName}</td>
                <td>{u.email}</td>
                <td>{u.roleName}</td>
                <td>{moment(u.createdAt).format("L")}</td>
                <td>
                  <>
                    <div className="actions-cont">
                      <button
                        className="open-user"
                        onClick={() => {
                          setSelectedUser(u);
                          setOpenEditModal(true);
                        }}
                      >
                        <svg viewBox="0 0 16 16">
                          <g>
                            <path d="M6.8,13.6c0.2,0,0.3,0.1,0.4,0.4V14c0,0.2-0.1,0.4-0.4,0.4h-6c-0.3,0-0.5-0.1-0.6-0.3C0.1,14,0,13.8,0,13.5 c0-1.2,0.5-2.2,1.3-3.1s1.8-1.2,3.1-1.3h2.6c0.6,0,1.2,0.1,1.7,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3-0.1,0.4 c-0.1,0.1-0.3,0.2-0.5,0.1C7.8,10.1,7.3,10,6.9,10H4.3c-1,0-1.8,0.4-2.5,1c-0.7,0.7-1,1.5-1,2.5c0,0.1,0,0.1,0.1,0.1H6.8z M5.6,8 C4.7,8,3.9,7.7,3.3,7.1C2.7,6.5,2.4,5.7,2.4,4.8c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9c0.9,0,1.7,0.3,2.3,0.9 c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3C7.2,7.7,6.5,8,5.6,8z M5.6,2.4c-0.7,0-1.2,0.3-1.7,0.7c-0.5,0.5-0.7,1-0.7,1.7 c0,0.7,0.3,1.3,0.7,1.7s1,0.7,1.7,0.7c0.7,0,1.3-0.3,1.7-0.7C7.7,6.1,8,5.5,8,4.8c0-0.7-0.3-1.3-0.7-1.7C6.8,2.7,6.3,2.4,5.6,2.4z M15.6,7.5C15.9,7.7,16,8,16,8.3s-0.1,0.6-0.4,0.8l-4.4,4.4c-0.2,0.2-0.5,0.4-0.8,0.4l-2.1,0.4c0,0,0,0-0.1,0C8.1,14.4,8,14.2,8,14 l0.4-2.1c0.1-0.3,0.2-0.6,0.4-0.8l4.4-4.4c0.3-0.2,0.5-0.4,0.9-0.4s0.6,0.1,0.9,0.4L15.6,7.5z M10.7,12.9l2.9-2.8l-1.3-1.3 l-2.9,2.8c-0.1,0.1-0.2,0.3-0.2,0.4l-0.3,1.4l1.3-0.3C10.4,13.1,10.6,13.1,10.7,12.9z M15.1,8.6c0.1-0.1,0.1-0.2,0.1-0.3 c0-0.1,0-0.2-0.1-0.3l-0.7-0.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1l-1,1l1.3,1.3L15.1,8.6z" />
                          </g>
                        </svg>
                        <div className="tooltip">Editar</div>
                      </button>
                      <button
                        className={`${
                          u.isActive ? "active-user" : "desactive-user"
                        }`}
                        onClick={
                          () => {
                            setSelectedUser(u);
                            setOpenChangeStatusModal(true);
                          }
                          /*  {
                          Swal.fire({
                            title: `¿Desea ${
                              u.isActive ? "desactivar" : "activar"
                            } al usuario?`,
                            showCancelButton: true,
                            cancelButtonText: "Cancelar",
                            confirmButtonText: `Aceptar`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              changeStatus(u.id).then((r) => {
                                if (r.data) {
                                  getUserByStatus();
                                  showSuccessPopup(
                                    "Datos guardados",
                                    "Se han guardado los datos correctamente"
                                  );
                                }
                              });
                            }
                          });
                        } */
                        }
                      >
                        <svg viewBox="0 0 16 16">
                          <g>
                            {!u.isActive && (
                              <path d="M6.9,9.2c1.2,0,2.2,0.5,3,1.3c0.8,0.8,1.2,1.8,1.3,3c0,0.2-0.1,0.5-0.2,0.6s-0.4,0.2-0.6,0.2H0.9c-0.2,0-0.5-0.1-0.6-0.2 S0,13.8,0,13.5c0-1.2,0.5-2.2,1.3-3c0.8-0.8,1.8-1.2,3-1.3H6.9z M10.3,13.6C10.4,13.6,10.4,13.6,10.3,13.6c0-1.1-0.3-1.9-0.9-2.6 c-0.7-0.6-1.5-1-2.5-1H4.3c-1,0-1.8,0.4-2.5,1c-0.6,0.7-1,1.5-1,2.5c0,0.1,0,0.1,0.1,0.1H10.3z M5.6,8C4.7,8,3.9,7.7,3.3,7.1 C2.7,6.5,2.4,5.7,2.4,4.8c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3 c0,0.9-0.3,1.7-0.9,2.3C7.3,7.7,6.5,8,5.6,8z M5.6,2.4c-0.7,0-1.2,0.2-1.7,0.7s-0.7,1-0.7,1.7c0,0.7,0.2,1.2,0.7,1.7 c0.4,0.4,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7C7.7,6.1,8,5.5,8,4.8c0-0.7-0.2-1.2-0.7-1.7S6.3,2.4,5.6,2.4z M14.2,7.2l1.3,1.3 c0.2,0.2,0.2,0.4,0,0.5c-0.2,0.2-0.4,0.2-0.5,0l-1.3-1.3l-1.3,1.3c-0.2,0.2-0.4,0.2-0.5,0c-0.2-0.2-0.2-0.4,0-0.5L13,7.2l-1.3-1.3 c-0.2-0.2-0.2-0.4,0-0.5c0.2-0.2,0.4-0.2,0.5,0l1.3,1.3l1.3-1.3c0.2-0.2,0.4-0.2,0.5,0c0.2,0.2,0.2,0.4,0,0.5L14.2,7.2z" />
                            )}
                            {u.isActive && (
                              <path d="M6.9,9.2c1.2,0,2.2,0.5,3.1,1.3s1.2,1.8,1.3,3.1c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3H0.9 c-0.3,0-0.5-0.1-0.6-0.3C0.1,13.9,0,13.7,0,13.5c0-1.2,0.5-2.2,1.3-3.1s1.8-1.2,3.1-1.3H6.9z M10.3,13.6 C10.4,13.6,10.4,13.5,10.3,13.6c0-1.1-0.3-1.9-1-2.6c-0.7-0.7-1.5-1-2.5-1H4.3c-1,0-1.8,0.4-2.5,1c-0.7,0.7-1,1.5-1,2.5 c0,0.1,0,0.1,0.1,0.1H10.3z M5.6,8C4.7,7.9,3.9,7.6,3.3,7C2.7,6.4,2.4,5.7,2.4,4.8c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-0.9,2.3-0.9 c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3C7.3,7.6,6.5,7.9,5.6,8z M5.6,2.4c-0.7,0-1.3,0.3-1.7,0.7 s-0.7,1-0.7,1.7c0,0.7,0.3,1.3,0.7,1.7s1,0.7,1.7,0.7c0.7,0,1.3-0.3,1.7-0.7S8,5.4,8,4.8c0-0.7-0.3-1.3-0.7-1.7S6.3,2.4,5.6,2.4z M15.9,5.4C16,5.6,16,5.8,15.9,6l-2.7,2.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1l-1.3-1.3c-0.2-0.2-0.2-0.4,0-0.6 c0.2-0.2,0.4-0.2,0.6,0l1.1,1.1l2.4-2.4C15.5,5.3,15.7,5.3,15.9,5.4z" />
                            )}
                          </g>
                        </svg>
                        <div className="tooltip">
                          {u.isActive ? "Desactivar" : "Activar"}
                        </div>
                      </button>
                    </div>
                  </>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedUser && openChangeStatusModal && (
        <ConfirmModal
          modalState={openChangeStatusModal}
          setModalState={setOpenChangeStatusModal}
          text={`¿Seguro que quieres ${
            selectedUser.isActive ? "Desactivar" : "Activar"
          } al usuario ${selectedUser.fullName}?`}
          OkAction={() => {
            if (selectedUser && selectedUser.id) {
              changeStatus(selectedUser.id).then((r) => {
                if (r.data) {
                  getUserByStatus();
                  setOpenConfirmModal(true);
                }
              });
            }
          }}
        />
      )}
      {selectedUser && openEditModal && (
        <UserModal
          setModalState={setOpenEditModal}
          modalState={openEditModal}
          user={selectedUser}
        ></UserModal>
      )}
      {openNewModal && (
        <UserModal
          setModalState={setOpenNewModal}
          modalState={openNewModal}
          user={null}
        ></UserModal>
      )}
      <SuccessModal
        setModalState={setOpenConfirmModal}
        modalState={openConfirmModal}
        text={"Usuario actualizado correctamente"}
        okAction={() => setOpenConfirmModal(false)}
      ></SuccessModal>
      {/* <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            <h6 className="m-0 font-weight-bold text-primary">
              Tabla de usuarios
            </h6>
            <div className="col col-lg-1" style={{ bottom: 7 }}>
              <Link
                to="/users/new"
                data-tip="Agregar usuario"
                data-effect="solid"
                className="btn btn-primary btn-circle"
              >
                +
              </Link>
            </div>
            <div className="col col-lg-3" style={{ marginTop: -7 }}>
              <ChangeStatus
                tooltip={`${
                  isActive ? "Filtrar por inactivos" : "Filtrar por activos"
                }`}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Loader loading={isLoading} />
            <table
              className="table table-bordered"
              id="example"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Rol</th>
                  <th>Creado el</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {users.map((u, i) => (
                  <tr key={i}>
                    <td>{u.fullName}</td>
                    <td>{u.email}</td>
                    <td>{u.roleName}</td>
                    <td>{moment(u.createdAt).format("L")}</td>
                    <td>
                      <>
                        <span
                          onClick={() =>
                            history.push("/users/edit", {
                              id: u.id,
                            })
                          }
                          className="margin-10 c-pointer"
                        >
                          <i
                            data-tip="Editar"
                            data-effect="solid"
                            className="fas fa-edit"
                          ></i>
                        </span>
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: `¿Desea ${
                                u.isActive ? "desactivar" : "activar"
                              } al usuario?`,
                              showCancelButton: true,
                              cancelButtonText: "Cancelar",
                              confirmButtonText: `Aceptar`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                changeStatus(u.id).then((r) => {
                                  if (r.data) {
                                    getUserByStatus();
                                    showSuccessPopup(
                                      "Datos guardados",
                                      "Se han guardado los datos correctamente"
                                    );
                                  }
                                });
                              }
                            });
                          }}
                          className="margin-10 c-pointer"
                        >
                          <i
                            data-tip="Desactivar usuario"
                            data-effect="solid"
                            className="fas fa-times"
                          ></i>
                        </span>
                      </>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Users;
