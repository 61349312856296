import React, { useContext, useEffect, useState } from "react";
import { withFormik } from "formik";
import { LoginModel } from "./_config";
import RoundedInput from "../Common/Inputs/RoundedInput";
import { saveToken, SignIn } from "../../services/auth";
import "./styles.css";
import Loader from "../Common/Loader";
import UserContext from "../../context/userContext";
import logo from "../../assets/img/logo.svg";
import FormInput from "../Common/Inputs/FormInput";
const Login = ({
  history,
  values,
  isValid,
  errors,
  touched,
  setTouched,
  handleChange,
  handleBlur,
  setSubmitting,
  initialValues,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUserInfo } = useContext(UserContext);

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      document.body.classList.add("bg-gradient-primary");
    } else {
      document.body.classList.remove("bg-gradient-primary");
    }
  }, []);

  const signIn = (e) => {
    setIsLoading(true);
    const { username, password } = values;
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        username: true,
        password: true,
      });
      setSubmitting(false);
      setIsLoading(false);
    } else {
      SignIn(username, password)
        .then((r) => {
          setIsLoading(false);
          if (!r) return;
          if (r.data && r.data.token) {
            saveToken(r.data.token);
            setUserInfo();
            history.replace("/companies");
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className="wrapper login">
      <div className="login-cont">
        <img src={logo} width="150" />

        <h2>Bienvenido</h2>

        <form id="login" className="user" onSubmit={(e) => signIn(e)}>
          <FormInput
            id="usernameId"
            name="username"
            label={"Correo electrónico"}
            className="form-control"
            type="text"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.username}
            touched={touched.username}
            pathSVG={
              "M8.1,0c2.3,0.1,4.1,0.9,5.6,2.4C15.2,3.9,15.9,5.7,16,8v0.7c0,0.8-0.3,1.4-0.7,2c-0.5,0.6-1.1,0.9-1.8,1c-0.6,0.1-1.2,0-1.7-0.3c-0.5-0.3-0.9-0.7-1.2-1.2c-0.7,1-1.7,1.5-3,1.5c-1.2,0-2.1-0.5-2.9-1.3c-0.7-0.9-1-1.9-0.8-3.1c0.1-0.8,0.5-1.4,1-2s1.2-0.9,1.9-1C7.4,4.2,8,4.3,8.6,4.5C9.2,4.7,9.7,5,10.1,5.4V4.8c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v4c0,0.6,0.3,1.1,0.7,1.5c0.4,0.4,1,0.5,1.6,0.4c0.4-0.1,0.8-0.3,1-0.7c0.3-0.4,0.4-0.8,0.4-1.2V8c0-2-0.7-3.7-2.1-5c-1.3-1.3-3-2-5-2c-1.4,0-2.6,0.4-3.6,1.1c-1,0.7-1.8,1.6-2.4,2.7C1.3,5.9,1,7.2,1.1,8.5c0.2,1.8,0.8,3.3,2,4.5c1.2,1.2,2.7,1.8,4.5,2C7.8,15,8,15.2,8,15.5C8,15.8,7.8,16,7.4,16c-2-0.2-3.7-0.9-5-2.2S0.3,10.9,0,8.9c-0.1-1.6,0.1-3.1,0.8-4.4C1.6,3.1,2.5,2,3.8,1.2C5.1,0.4,6.5,0,8.1,0z M7.5,10.7c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9c0-0.8-0.3-1.4-0.8-1.9C8.8,5.6,8.2,5.4,7.5,5.3c-0.8,0-1.4,0.3-1.9,0.8C5.1,6.6,4.8,7.2,4.8,8c0,0.8,0.3,1.4,0.8,1.9C6.1,10.4,6.7,10.6,7.5,10.7z"
            }
          />

          <FormInput
            id="password"
            className="form-control"
            type="password"
            label={"Contraseña"}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password}
            touched={touched.password}
            pathSVG={
              "M15,8.5v6c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.6,0.4-1.1,0.4h-11c-0.4,0-0.8-0.1-1.1-0.4C1.2,15.3,1,14.9,1,14.5v-6c0-0.4,0.1-0.8,0.4-1.1C1.7,7.1,2.1,7,2.5,7H3V5c0-1.4,0.5-2.5,1.5-3.5C5.5,0.5,6.6,0,8,0c1.4,0,2.6,0.5,3.5,1.5S13,3.7,13,5.1V7h0.5c0.4,0,0.8,0.1,1.1,0.4S15,8.1,15,8.5z M14,14.5v-6c0-0.1,0-0.3-0.1-0.4S13.6,8,13.5,8h-11C2.4,8,2.2,8,2.1,8.1C2.1,8.2,2,8.4,2,8.5v6c0,0.1,0,0.3,0.1,0.4C2.2,14.9,2.4,15,2.5,15h11c0.1,0,0.3,0,0.4-0.1S14,14.6,14,14.5zM4,7h8V5c0-1.1-0.4-2-1.2-2.8S9.1,1,8,1C6.9,1,6,1.4,5.2,2.2S4,3.9,4,5V7z M8.4,12.9c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4v-2c0-0.2,0.1-0.3,0.2-0.4C7.7,9.9,7.8,9.9,8,9.9c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4v2C8.6,12.7,8.6,12.8,8.4,12.9z"
            }
          />
          <div className="cont-form">
            <button id="login-btn" className="btn full">
              Iniciar sesión
            </button>
          </div>
        </form>

        <a href="forgot-password.html">Olvidé mi contraseña</a>
      </div>
    </div>
    /* <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5 form-center">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-photo"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Bienvenido!</h1>
                    </div>
                    <form className="user" onSubmit={(e) => signIn(e)}>
                      <div className="form-group">
                        <RoundedInput
                          type="text"
                          id="usernameId"
                          name="username"
                          placeholder="Correo"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.username}
                          touched={touched.username}
                        />
                      </div>
                      <div className="form-group">
                        <RoundedInput
                          type="password"
                          id="passwordId"
                          name="password"
                          placeholder="Contraseña"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password}
                          touched={touched.password}
                        />
                      </div>
                      {isLoading ? (
                        <div className="loader-login">
                          <Loader loading={isLoading} />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-user btn-block"
                        >
                          Iniciar sesion
                        </button>
                      )}
                    </form>
                    <hr />
                    <div className="text-center">
                      <a className="small" href="forgot-password.html">
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                    <div className="text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    username: "",
    password: "",
  }),
  validationSchema: LoginModel,
  displayName: "LoginForm",
})(Login);
