import React from "react";
import { Button, Modal as ReactstrapModal } from "reactstrap";

const SuccessModal = ({
  modalState,
  setModalState,
  className,
  backdrop = true,
  text,
  okAction,
}) => {
  const toggle = () => setModalState(!modalState);
  return (
    <ReactstrapModal
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <div id="check-popup" class="popup-cont alert">
        <div className="modal">
          <svg viewBox="0 0 16 16">
            <g>
              <path d="M14.6,11.9c-0.7,1.2-1.6,2.1-2.8,2.8c-1.1,0.7-2.4,1-3.9,1c-1.4,0-2.7-0.4-3.9-1C3,13.9,2.1,13,1.4,11.9 c-0.7-1.1-1-2.4-1-3.9c0-1.4,0.4-2.7,1-3.9C2.1,3,3,2.1,4.1,1.4c1.1-0.7,2.4-1,3.9-1c1.4,0,2.7,0.4,3.9,1C13,2.1,13.9,3,14.6,4.1 c0.7,1.1,1,2.4,1,3.9C15.7,9.4,15.3,10.7,14.6,11.9z M4.6,2.2c-1,0.6-1.8,1.4-2.4,2.4C1.6,5.6,1.3,6.7,1.3,8c0,1.3,0.3,2.4,0.9,3.4 c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.1,0.9,3.4,0.9c1.3,0,2.4-0.3,3.4-0.9c1-0.6,1.8-1.4,2.4-2.4c0.6-1,0.9-2.1,0.9-3.4 c0-1.3-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.8-2.4-2.4c-1-0.6-2.1-0.9-3.4-0.9C6.7,1.3,5.6,1.6,4.6,2.2z M6.7,10.2L4.8,8.3 c-0.2-0.2-0.2-0.4,0-0.7c0.2-0.2,0.4-0.2,0.7,0L7,9.2l3.5-3.5c0.2-0.2,0.4-0.2,0.7,0c0.2,0.2,0.2,0.4,0,0.7l-3.8,3.8 C7.2,10.4,6.9,10.4,6.7,10.2z" />
            </g>
          </svg>

          <p>{text}</p>

          <div className="cont-form text-center">
            <button className="btn close-popup" onClick={okAction}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </ReactstrapModal>
  );
};

export default SuccessModal;
