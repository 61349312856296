import React from "react";

const SelectInput = ({
  id,
  name,
  label,
  data,
  error,
  touched,
  layoutClass,
  selectClass,
  ...otherProps
}) => {
  return (
    <div className={`form-group search-cont ${layoutClass}`}>
      <label htmlFor={id}>{label}</label>
      <select {...otherProps} name={name} className={selectClass}>
        <option value="">Seleccione...</option>
        {data &&
          data.map((s) => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
      </select>
      {error && touched && <div className="form-input-error">{error}</div>}
    </div>
  );
};

export default SelectInput;
