import * as Yup from "yup";

export const CompanyAdminModel = Yup.object().shape({
  name: Yup.string().required("El nombre de la empresa es requerido"),
});

export const DashboardAdminModel = Yup.object().shape({
  name: Yup.string().required("El nombre del tablero es requerido"),
  url: Yup.string().required("URL requerida"),
});
