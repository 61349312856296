import React, { useState, useEffect } from "react";
import { Modal as ReactstrapModal } from "reactstrap";
import { withFormik } from "formik";
import FormInput from "../../Common/Inputs/FormInput";
import { userModel } from "../../Users/_config";
import {
  createUser,
  getRoles,
  getUserById,
  updateUser,
} from "../../../services/users";
import SelectInput from "../../Common/Inputs/SelectInput";
import { showSuccessPopup } from "../../../utils/Popups";
import SuccessModal from "./SuccessModal";

const UserModal = ({
  modalState,
  setModalState,
  user,
  className,
  backdrop = true,
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  setValues,
  isValid,
  initialValues,
  setTouched,
  setSubmitting,
  history,
}) => {
  const toggle = () => setModalState(!modalState);
  const [roles, setRoles] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  useEffect(() => {
    getRoles().then((r) => {
      if (r.data) {
        setRoles([...r.data]);
      }
    });

    if (user && user.id) {
      getUserById(user.id).then((r) => {
        if (!r) return;

        if (r.data) {
          const { data } = r;
          setValues({
            id: data.id,
            name: data.name,
            fatherLastName: data.fatherLastName,
            motherLastName: data.motherLastName,
            email: data.email,
            roleId: data.roleId,
            password: data.password || "",
          });
          setIsLoading(false);
        }
      });
    } else setIsLoading(false);
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const { name, fatherLastName, motherLastName, email, password, roleId } =
    values;
  const goUsers = () => history.replace("/users");

  const responseSuccess = () => {
    showSuccessPopup(
      "Datos guardados",
      "Se han guardado los datos correctamente"
    );
    goUsers();
  };
  const makeSubmit = (e) => {
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        name: true,
        fatherLastName: true,
        motherLastName: true,
        email: true,
        password: true,
        roleId: true,
      });
      setSubmitting(false);
    } else {
      if (values.id)
        updateUser(values).then(() => {
          setConfirmModal(true);
        });
      else
        createUser(values).then(() => {
          setConfirmModal(true);
        });
    }
  };
  return (
    <ReactstrapModal
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <div id="usernew" className="popup-cont big">
        <div className="modal">
          <h3>Editar usuario</h3>
          <form onSubmit={makeSubmit}>
            <div className="form-line">
              <FormInput
                id="name"
                name="name"
                label={"Nombre"}
                className="form-control"
                type="text"
                value={name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                touched={touched.name}
              />
              <FormInput
                id="fatherLastNameId"
                name="fatherLastName"
                label="Apellido paterno"
                value={fatherLastName}
                error={errors.fatherLastName}
                touched={touched.fatherLastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-line">
              <FormInput
                id="motherLastNameId"
                name="motherLastName"
                label="Apellido materno"
                value={motherLastName}
                error={errors.motherLastName}
                touched={touched.motherLastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormInput
                type="email"
                id="emailId"
                name="email"
                label="Email"
                value={email}
                error={errors.email}
                touched={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-line">
              <FormInput
                type="password"
                id="passwordId"
                name="password"
                label="Contraseña"
                value={password}
                error={errors.password}
                touched={touched.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <SelectInput
                id="roleUnique"
                name="roleId"
                label=""
                value={roleId}
                data={roles}
                placeholder=""
                error={errors.roleId}
                touched={touched.roleId}
                onChange={handleChange}
                onBlur={handleBlur}
                layoutClass={"cont-form"}
                selectClass={"form-control"}
              />
            </div>
            <div className="buttons-cont">
              <div className="btn secondary close-popup" onClick={toggle}>
                Cancelar
              </div>
              <button type="submit" className="btn close-popup">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SuccessModal
        modalState={confirmModal}
        setModalState={setConfirmModal}
        text={`Usuario ${values.id ? "actualizado" : "Creado"} correctamente`}
        okAction={() => {
          setConfirmModal(false);
          setModalState(false);
        }}
      ></SuccessModal>
    </ReactstrapModal>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    id: "",
    name: "",
    fatherLastName: "",
    motherLastName: "",
    email: "",
    password: "",
    roleId: "",
  }),
  validationSchema: userModel,
  displayName: "userNForm",
})(UserModal);
