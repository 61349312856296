import * as Yup from "yup";

export const changePasswordModel = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required("La contraseña actual es requerida"),
  newPassword: Yup.string().trim().required("La nueva contraseña es requerida"),
  repeatedPassword: Yup.string()
    .trim()
    .required("Repetir la contraseña es obligatorio")
    .oneOf([Yup.ref("newPassword"), null], "Ambas contraseñas deben coincidir"),
});
