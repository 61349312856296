import React, { useEffect, useState } from "react";
import {
  deleteCompany,
  getAllCompaniesToAccess,
  changeStatus,
} from "../../services/companies";
import * as moment from "moment";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PageHeading from "../Layout/PageHeading";
import Loader from "../Common/Loader";
import Swal from "sweetalert2";
import { showSuccessPopup } from "../../utils/Popups";
import logoDefault from "../../assets/img/logo-default.png";
import CompanyModal from "./CompanyModal";
import ConfirmModal from "../Common/containers/ConfirmModal";
import SuccessModal from "../Common/containers/SuccessModal";

const CompanyAdminList = ({ history }) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getCompanies();
  }, [isActive, searchTerm]);

  const getCompanies = () => {
    getAllCompaniesToAccess(isActive, searchTerm).then((r) => {
      if (!r) return r;
      const { data } = r;
      if (data)
        setCompanies([...data].sort((a, b) => a.name.localeCompare(b.name)));
      else setCompanies([]);
      setIsLoading(false);
    });
  };

  return (
    <>
      <ReactTooltip />
      <PageHeading title="Empresas" description="" />
      <div className="card shadow mb-4">
        <div className="table-head">
          <div className="col-filters">
            <div className="search-cont">
              <input
                type="text"
                placeholder="Buscar empresa"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <svg viewBox="0 0 16 16">
                <g>
                  <path d="M15.6,15c0.2,0.2,0.2,0.4,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2-0.1-0.4-0.2l-4.3-4.3c-1.1,1-2.5,1.5-4.1,1.5 c-1.8,0-3.3-0.7-4.4-1.9C0.9,9.8,0.3,8.3,0.2,6.5c0-1.8,0.6-3.3,1.8-4.5c1.2-1.2,2.7-1.8,4.5-1.9c1.8,0,3.3,0.7,4.5,1.9 s1.8,2.7,1.9,4.5c0,1.6-0.5,3-1.5,4.1L15.6,15z M6.5,11.9c1.5,0,2.8-0.6,3.8-1.5c1-1,1.5-2.3,1.6-3.8c0-1.5-0.6-2.8-1.5-3.8 c-1-1-2.3-1.5-3.8-1.5C5,1.2,3.8,1.8,2.7,2.8c-1,1-1.5,2.2-1.5,3.8c0,1.5,0.6,2.8,1.5,3.8C3.8,11.3,5,11.8,6.5,11.9z" />{" "}
                </g>
              </svg>
            </div>
            <div className="checks-cont">
              <label>
                <input
                  type="radio"
                  name="active"
                  checked={isActive === ""}
                  onClick={() => setIsActive("")}
                />
                <div>Todos</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="active"
                  checked={isActive}
                  onClick={() => setIsActive(true)}
                />
                <div>Activos</div>
              </label>
              <label>
                <input
                  type="radio"
                  name="active"
                  checked={!isActive && isActive !== ""}
                  onClick={() => setIsActive(false)}
                />
                <div>Inactivos</div>
              </label>
            </div>
          </div>

          <button
            className="btn icon secondary open-user"
            onClick={() => {
              setSelectedCompany(null);
              setOpenCompanyModal(true);
            }}
          >
            <svg viewBox="0 0 16 16">
              <g>
                <path d="M14.8,12c-0.7,1.2-1.6,2.1-2.8,2.8c-1.2,0.7-2.5,1.1-4,1.1c-1.5,0-2.8-0.4-4-1.1c-1.2-0.7-2.1-1.6-2.8-2.8 c-0.7-1.2-1.1-2.5-1.1-4c0-1.5,0.4-2.8,1.1-4C1.9,2.8,2.8,1.9,4,1.2c1.2-0.7,2.5-1.1,4-1.1c1.5,0,2.8,0.4,4,1.1 c1.2,0.7,2.1,1.6,2.8,2.8c0.7,1.2,1.1,2.5,1.1,4C15.9,9.5,15.5,10.8,14.8,12z M4.5,2C3.5,2.6,2.6,3.5,2,4.5c-0.6,1-0.9,2.2-1,3.5 c0,1.3,0.3,2.5,1,3.5c0.6,1.1,1.4,1.9,2.5,2.5c1,0.6,2.2,0.9,3.5,1c1.3,0,2.5-0.3,3.5-1c1.1-0.6,1.9-1.4,2.5-2.5 c0.6-1,0.9-2.2,1-3.5c0-1.3-0.3-2.5-1-3.5c-0.6-1.1-1.4-1.9-2.5-2.5c-1-0.6-2.2-0.9-3.5-1C6.7,1.1,5.5,1.4,4.5,2z M7.5,8.5H5 C4.7,8.5,4.6,8.3,4.5,8c0-0.3,0.2-0.5,0.5-0.5h2.5V5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v2.5H11c0.3,0,0.5,0.2,0.5,0.5 c0,0.3-0.2,0.5-0.5,0.5H8.5V11c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V8.5z" />
              </g>
            </svg>
            Nueva empresa
          </button>
        </div>
        <div className="card-body">
          <div className="table-cont">
            <Loader loading={isLoading} />
            <table
              className="table table-bordered"
              id="example"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Nombre</th>
                  <th>Tableros</th>
                  <th>Creado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((u, i) => (
                  <tr key={i}>
                    <td style={{ padding: "10px 20px" }}>
                      {u.logo ? (
                        <img src={u.logo} style={{ maxHeight: "30px" }} />
                      ) : (
                        <img src={logoDefault} width="30" />
                      )}
                    </td>
                    <td>{u.name}</td>
                    <td>{u.dashboardCount}</td>
                    <td>{moment(u.createdAt).format("L")}</td>
                    <td>
                      <div className="actions-cont">
                        <button
                          className="edit-user"
                          onClick={() => {
                            setSelectedCompany(u);
                            setOpenCompanyModal(true);
                          }}
                        >
                          <svg viewBox="0 0 16 16">
                            <g>
                              <path d="M7.2,3c0,0.3-0.2,0.4-0.4,0.4H3.1c-0.4,0-0.7,0.1-0.9,0.4C2,4.1,1.8,4.4,1.8,4.8v8c0,0.4,0.1,0.7,0.4,0.9 c0.3,0.2,0.6,0.4,0.9,0.4h8c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.4-0.6,0.4-0.9V9.3c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v3.6 c0,0.6-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.6-1.6,0.6h-8c-0.6,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.6v-8c0-0.6,0.2-1.2,0.6-1.6 c0.4-0.4,1-0.6,1.6-0.6h3.6C7,2.6,7.1,2.8,7.2,3z M13,0.9c0.5,0,0.9,0.2,1.3,0.5l0.3,0.3c0.4,0.4,0.5,0.8,0.5,1.3s-0.2,0.9-0.5,1.3 l-6.1,6.2c-0.2,0.2-0.5,0.4-0.9,0.5L5,11.5c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.4l0.6-2.5c0.1-0.3,0.3-0.6,0.5-0.9 l6.2-6.1C12.1,1.1,12.5,0.9,13,0.9z M5.5,10.5l1.8-0.4c0.2,0,0.3-0.1,0.4-0.3L12.5,5L11,3.5L6.2,8.2C6.1,8.3,6,8.5,6,8.6L5.5,10.5z M13,1.8c-0.2,0-0.5,0.1-0.6,0.3l-0.7,0.8l1.6,1.6l0.8-0.7c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6l-0.3-0.3 C13.4,1.9,13.2,1.8,13,1.8z" />{" "}
                            </g>
                          </svg>
                          <div className="tooltip">Editar</div>
                        </button>
                        <button
                          className="desactive-user"
                          onClick={() => {
                            setSelectedCompany(u);
                            setChangeStatusModal(true);
                          }}
                        >
                          <svg viewBox="0 0 16 16">
                            <g>
                              {u.isActive ? (
                                <path d="M14.4,11.8c-0.7,1.1-1.6,2-2.7,2.7c-1.1,0.7-2.4,1-3.8,1c-1.4,0-2.6-0.4-3.8-1c-1.1-0.7-2-1.6-2.7-2.7 c-0.7-1.1-1-2.4-1-3.8c0-1.4,0.4-2.6,1-3.8c0.7-1.1,1.6-2,2.7-2.7c1.1-0.7,2.4-1,3.8-1c1.4,0,2.6,0.4,3.8,1c1.1,0.7,2,1.6,2.7,2.7 c0.7,1.1,1,2.4,1,3.8C15.4,9.4,15.1,10.6,14.4,11.8z M4.7,2.4c-1,0.6-1.8,1.3-2.3,2.3C1.8,5.7,1.5,6.8,1.5,8c0,1.2,0.3,2.3,0.9,3.3 c0.6,1,1.3,1.8,2.3,2.3c1,0.6,2.1,0.9,3.3,0.9c1.2,0,2.3-0.3,3.3-0.9c1-0.6,1.8-1.3,2.3-2.3c0.6-1,0.9-2.1,0.9-3.3 c0-1.2-0.3-2.3-0.9-3.3c-0.6-1-1.3-1.8-2.3-2.3c-1-0.6-2.1-0.9-3.3-0.9C6.8,1.5,5.7,1.8,4.7,2.4z M6.5,5.8L8,7.3l1.5-1.5 c0.2-0.2,0.4-0.2,0.6,0c0.2,0.2,0.2,0.4,0,0.6L8.7,8l1.5,1.5c0.2,0.2,0.2,0.4,0,0.6c-0.2,0.2-0.4,0.2-0.6,0L8,8.7l-1.5,1.5 c-0.2,0.2-0.4,0.2-0.6,0c-0.2-0.2-0.2-0.4,0-0.6L7.3,8L5.8,6.5C5.6,6.2,5.6,6,5.8,5.8C6,5.6,6.2,5.6,6.5,5.8z" />
                              ) : (
                                <path d="M14.6,11.9c-0.7,1.2-1.6,2.1-2.8,2.8c-1.1,0.7-2.4,1-3.9,1c-1.4,0-2.7-0.4-3.9-1C3,13.9,2.1,13,1.4,11.9 c-0.7-1.1-1-2.4-1-3.9c0-1.4,0.4-2.7,1-3.9C2.1,3,3,2.1,4.1,1.4c1.1-0.7,2.4-1,3.9-1c1.4,0,2.7,0.4,3.9,1C13,2.1,13.9,3,14.6,4.1 c0.7,1.1,1,2.4,1,3.9C15.7,9.4,15.3,10.7,14.6,11.9z M4.6,2.2c-1,0.6-1.8,1.4-2.4,2.4C1.6,5.6,1.3,6.7,1.3,8c0,1.3,0.3,2.4,0.9,3.4 c0.6,1,1.4,1.8,2.4,2.4c1,0.6,2.1,0.9,3.4,0.9c1.3,0,2.4-0.3,3.4-0.9c1-0.6,1.8-1.4,2.4-2.4c0.6-1,0.9-2.1,0.9-3.4 c0-1.3-0.3-2.4-0.9-3.4c-0.6-1-1.4-1.8-2.4-2.4c-1-0.6-2.1-0.9-3.4-0.9C6.7,1.3,5.6,1.6,4.6,2.2z M6.7,10.2L4.8,8.3 c-0.2-0.2-0.2-0.4,0-0.7c0.2-0.2,0.4-0.2,0.7,0L7,9.2l3.5-3.5c0.2-0.2,0.4-0.2,0.7,0c0.2,0.2,0.2,0.4,0,0.7l-3.8,3.8 C7.2,10.4,6.9,10.4,6.7,10.2z" />
                              )}
                            </g>
                          </svg>
                          <div className="tooltip">
                            {u.isActive ? "Desactivar" : "Activar"}
                          </div>
                        </button>
                        <button
                          className="delete-user"
                          onClick={() => {
                            setSelectedCompany(u);
                            setConfirmDeleteModal(true);
                          }}
                        >
                          <svg viewBox="0 0 16 16">
                            <g>
                              <path d="M11.1,2.8h2.6c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4h-0.4v9.1c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6H5 c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V3.7H2.4C2.1,3.7,2,3.5,1.9,3.2C2,3,2.1,2.8,2.4,2.8h2.6l0.7-1.1 c0.3-0.4,0.6-0.6,1.1-0.6h2.5c0.5,0,0.8,0.2,1.1,0.6L11.1,2.8z M4.1,13.7C4.3,13.9,4.6,14,5,14.1H11c0.4,0,0.7-0.1,0.9-0.4 c0.2-0.3,0.4-0.6,0.4-0.9V3.7H3.7v9.1C3.7,13.1,3.8,13.4,4.1,13.7z M5.4,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4 c0.3,0,0.4,0.2,0.4,0.4v6.1C5.8,12.2,5.7,12.3,5.4,12.3z M10,2.8L9.6,2.2C9.5,2,9.4,1.9,9.2,1.9H6.8C6.6,1.9,6.5,2,6.4,2.2L6,2.8 H10z M8,12.3c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C8.4,12.2,8.3,12.3,8,12.3z M10.6,12.3 c-0.3,0-0.4-0.2-0.4-0.4V5.8c0-0.3,0.2-0.4,0.4-0.4c0.3,0,0.4,0.2,0.4,0.4v6.1C11,12.2,10.9,12.3,10.6,12.3z" />{" "}
                            </g>
                          </svg>
                          <div className="tooltip">Eliminar</div>
                        </button>
                      </div>
                    </td>

                    {/*  <td>{u.name}</td>
                    <td>{u.dashboardCount}</td>
                    <td>{moment(u.createdAt).format("L")}</td>
                    <td>
                      <>
                        <span
                          onClick={() =>
                            history.push("/admin/companies/edit", {
                              id: u.id,
                            })
                          }
                          className="margin-10 c-pointer"
                        >
                          <i
                            data-tip="Editar"
                            data-effect="solid"
                            className="fas fa-edit"
                          ></i>
                        </span>
                        <span
                          onClick={() => {
                            Swal.fire({
                              title: "¿Desea eliminar la empresa?",
                              html:
                                "IMPORTANTE: Al eliminar la empresa, tambien serán eliminados los tableros relacionados a ella.",
                              cancelButtonText: "Cancelar",
                              showCancelButton: true,
                              confirmButtonText: "Eliminar",
                              confirmButtonColor: "#df4759",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteCompany(u.id).then((r) => {
                                  if (r.data) {
                                    getCompanies();
                                    showSuccessPopup(
                                      "Datos guardados",
                                      "Se han guardado los datos correctamente"
                                    );
                                  }
                                });
                              }
                            });
                          }}
                          className="margin-10 c-pointer"
                        >
                          <i
                            data-tip="Eliminar empresa"
                            data-effect="solid"
                            className="fas fa-trash"
                          ></i>
                        </span>
                      </>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {openCompanyModal && (
          <CompanyModal
            modalState={openCompanyModal}
            setModalState={setOpenCompanyModal}
            company={selectedCompany}
            refresh={() => getCompanies()}
          />
        )}
        {selectedCompany && confirmDeleteModal && (
          <ConfirmModal
            modalState={confirmDeleteModal}
            setModalState={setConfirmDeleteModal}
            text={`Seguro que quieres eliminar esta empresa? :${selectedCompany.name}`}
            OkAction={() => {
              deleteCompany(selectedCompany.id).then((r) => {
                if (r.data) {
                  getCompanies();
                  setSuccessModal(true);
                }
              });
            }}
          />
        )}
        {selectedCompany && changeStatusModal && (
          <ConfirmModal
            modalState={changeStatusModal}
            setModalState={setChangeStatusModal}
            text={`¿Seguro que quieres cambiar el estatus esta empresa? >${selectedCompany.name}`}
            OkAction={() => {
              changeStatus(selectedCompany.id).then((r) => {
                if (r.data) {
                  getCompanies();
                  setSuccessModal(true);
                }
              });
            }}
          />
        )}
        <SuccessModal
          modalState={successModal}
          setModalState={setSuccessModal}
          okAction={() => setSuccessModal(false)}
          text={`Se realizó la acción correctamente`}
        />
      </div>
    </>
  );
};

export default CompanyAdminList;
