import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import { CompanyAdminModel } from "./_config";
import {
  getCompanyById,
  createCompany,
  updateCompany,
} from "../../services/companies";

import PageHeading from "../Layout/PageHeading";
import FormInput from "../Common/Inputs/FormInput";
import FormButtons from "../Common/buttons/FormButtons";
import CustomModal from "../Common/containers/CustomModal";
import { showSuccessPopup } from "../../utils/Popups";
import Loader from "../Common/Loader";

const CompanyAdminForm = ({
  history,
  location,
  values,
  setValues,
  errors,
  isValid,
  touched,
  setTouched,
  setSubmitting,
  handleChange,
  handleBlur,
  initialValues,
}) => {
  const [dashboards, setDashboards] = useState([]);
  const [dashboardInput, setDashboardInput] = useState({ name: "", url: "" });
  const [disabledButton, setDisabledButton] = useState(true);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (location.state && location.state.id)
      getCompanyById(location.state.id).then((r) => {
        if (!r) return;
        const { data } = r;
        if (data)
          setValues({
            id: data.id,
            name: data.name,
          });
        if (data && data.dashboards) setDashboards([...data.dashboards]);
        setIsLoading(false);
      });
    else setIsLoading(false);
  }, [location, setValues]);

  useEffect(() => {
    if (dashboardInput.name && dashboardInput.url) setDisabledButton(false);
    else setDisabledButton(true);
  }, [dashboardInput]);

  useEffect(() => {
    if (!modal) {
      setDashboardInput({ name: "", url: "" });
    }
  }, [modal]);

  const addDashboardToTable = () => {
    if (Number.isInteger(dashboardInput.index)) {
      const newArray = [...dashboards];
      newArray[dashboardInput.index] = { ...dashboardInput };
      setDashboards([...newArray]);
    } else setDashboards([...dashboards, { ...dashboardInput }]);
  };
  const goCompanies = () => history.replace("/admin/companies");

  const responseSuccess = () => {
    showSuccessPopup(
      "Datos guardados",
      "Se han guardado los datos correctamente"
    );
    goCompanies();
  };

  const makeSubmit = (e) => {
    e.preventDefault();
    if (!isValid || initialValues === values) {
      setTouched({
        name: true,
      });
      setSubmitting(false);
    } else {
      if (location.state && location.state.id) {
        updateCompany({ ...values, dashboards: dashboards }).then((r) => {
          if (!r) return;
          const { data } = r;
          if (data) responseSuccess();
        });
        return;
      }
      createCompany({ ...values, dashboards: dashboards }).then((r) => {
        if (!r) return;
        const { data } = r;
        if (data) {
          responseSuccess();
        }
      });
    }
  };

  const loadModal = (i, id, name, url) => {
    setModal(true);
    setDashboardInput({ id, name, url, index: i });
  };

  const deleteDashboard = (index) => {
    const filtered = dashboards.filter((_, i) => i !== index);
    setDashboards([...filtered]);
  };

  const { name } = values;
  return (
    <>
      <PageHeading
        title={`${
          location.state && location.state.id ? "Actualizar" : "Crear"
        } empresa`}
        description={`${
          location.state && location.state.id ? "Actualiza" : "Crea"
        } el nombre de la empresa y sus tableros correspondientes.`}
      />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <form onSubmit={makeSubmit}>
          <FormInput
            id="nameId"
            name="name"
            label="Nombre de la empresa"
            value={name}
            placeholder="Ejemplo: Amazon"
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <PageHeading
            style={{ marginTop: 40 }}
            title="Tableros"
            description="Gestiona los tableros de la empresa."
          />
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <div className="row">
                <h6 className="m-0 font-weight-bold text-primary">
                  Agrega tableros
                </h6>
                <div className="col col-lg-4" style={{ bottom: 7 }}>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(true);
                    }}
                    data-tip="Agregar tablero"
                    data-effect="solid"
                    className="btn btn-primary btn-circle"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="example"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Tablero</th>
                      <th>URL</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboards.map((u, i) => (
                      <tr key={i}>
                        <td>{u.name}</td>
                        <td>{u.url}</td>
                        <td>
                          <>
                            <span className="margin-10 c-pointer">
                              <i
                                onClick={() =>
                                  loadModal(i, u.id, u.name, u.url)
                                }
                                data-tip="Editar"
                                data-effect="solid"
                                className="fas fa-edit"
                              ></i>
                            </span>
                            <span className="margin-10 c-pointer">
                              <i
                                onClick={() => deleteDashboard(i)}
                                data-tip="Eliminar tablero"
                                data-effect="solid"
                                className="fas fa-times"
                              ></i>
                            </span>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <FormButtons cancelAction={goCompanies} />
        </form>
      )}
      <CustomModal
        disabled={disabledButton}
        modalState={modal}
        setModalState={setModal}
        title="Agregar tablero"
        size="lg"
        OkAction={addDashboardToTable}
      >
        <div className="row">
          <div className="col col-lg-4">
            <FormInput
              id="dashboardNameId"
              name="dashboardName"
              placeholder="Nombre del tablero"
              value={dashboardInput.name}
              onChange={(e) =>
                setDashboardInput({
                  ...dashboardInput,
                  name: e.currentTarget.value,
                })
              }
            />
          </div>
          <div className="col col-lg-6">
            <FormInput
              id="dashboardUrlId"
              name="dashboardUrl"
              placeholder="URL"
              value={dashboardInput.url}
              onChange={(e) =>
                setDashboardInput({
                  ...dashboardInput,
                  url: e.currentTarget.value,
                })
              }
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    id: "",
    name: "",
  }),
  validationSchema: CompanyAdminModel,
  displayName: "CompanyAdminForm",
})(CompanyAdminForm);
