import React, { useState, useEffect } from "react";
import { Modal as ReactstrapModal } from "reactstrap";
import { withFormik } from "formik";
import FormInput from "../Common/Inputs/FormInput";
import { DashboardAdminModel } from "./_config";

import SelectInput from "../Common/Inputs/SelectInput";
import { showSuccessPopup } from "../../utils/Popups";
import SuccessModal from "../Common/containers/SuccessModal";
import { addAccessToDashboard } from "../../services/dashboards";

const DashboardModal = ({
  modalState,
  setModalState,
  dashboardInput,
  setDashboardInput,
  className,
  backdrop = true,
  values,
  okAction,
  handleBlur,
  handleChange,
  errors,
  touched,
  setValues,
  isValid,
  initialValues,
  setTouched,
  setSubmitting,
  history,
}) => {
  const toggle = () => setModalState(!modalState);

  return (
    <ReactstrapModal
      isOpen={modalState}
      toggle={toggle}
      backdrop={backdrop}
      className={className}
    >
      <div id="boardnew" className="popup-cont big">
        <div className="modal">
          <h3>Crear tablero</h3>
          <FormInput
            type="text"
            id="dashboardNameId"
            name="dashboardName"
            label={"Nombre"}
            value={dashboardInput.name}
            error={errors.name}
            touched={touched}
            onChange={(e) =>
              setDashboardInput({
                ...dashboardInput,
                name: e.currentTarget.value,
              })
            }
          />
          <FormInput
            type="text"
            id="dashboardUrlId"
            name="dashboardUrl"
            error={errors.url}
            label={"URL"}
            touched={touched}
            value={dashboardInput.url}
            onChange={(e) =>
              setDashboardInput({
                ...dashboardInput,
                url: e.currentTarget.value,
              })
            }
          />

          <div className="buttons-cont">
            <div className="btn secondary close-newboard" onClick={toggle}>
              Cancelar
            </div>
            <button
              className="btn close-newboard"
              onClick={() => {
                okAction();
                toggle();
              }}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </ReactstrapModal>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    url: "",
  }),
  validationSchema: DashboardAdminModel,
  displayName: "DashboardAdminForm",
})(DashboardModal);
