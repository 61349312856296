import React, { useEffect, useState } from "react";
import {
  getDashboardsByCompany,
  getAllDashboardsByCompany,
} from "../../services/dashboards";
import Loader from "../Common/Loader";
import PageHeading from "../Layout/PageHeading";
import logoDefaultT from "../../assets/img/logo-default-tumb.png";

const Dashboards = ({ location, history }) => {
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(location.pathname);
    if (!location.state) {
      history.replace("/companies");
    } else {
      if (location.pathname.includes("/access")) {
        getAllDashboardsByCompany(location.state.companyId).then((r) => {
          setDashboards([...r.data]);
          setIsLoading(false);
        });
      } else {
        getDashboardsByCompany(location.state.companyId).then((r) => {
          setDashboards([...r.data]);
          setIsLoading(false);
        });
      }
    }
  }, [location, history]);

  const goForward = (dashboardId, dashboardName, url) => {
    if (location.state && location.state.isAccessRoute) {
      history.push("/access/companies/dashboards/users", {
        dashboardId,
        companyName: location.state.name,
        dashboardName,
      });
      return;
    }
    history.push("/companies/dashboards/info", {
      dashboardId,
      biUrl: url,
    });
  };

  return (
    <>
      <PageHeading
        title={`${location.state && location.state.name} - Tableros`}
        description={`Seleccione uno de los tableros para ${
          location.state && location.state.isAccessRoute
            ? "gestionar los accesos de los usuarios"
            : "consultar sus estadisticas"
        }`}
      />
      <div className="cards-cont">
        {isLoading && <Loader loading={isLoading} />}
        {dashboards.map((c, k) => (
          <a
            key={k}
            onClick={() => goForward(c.id, c.name, c.url)}
            className="card tablero"
          >
            <div className="inline">
              {c.logo ? (
                <img src={c.logo} className="image" />
              ) : (
                <img src={logoDefaultT} className="image" />
              )}
              <div className="name">{c.name}</div>
            </div>
            <div clclassNameass="tag">Generales</div>
          </a>
          // <Card
          //   key={c.id}
          //   title={c.name}
          //   icon="far fa-chart-bar"
          //   borderColor="primary"
          //   onClick={() => goForward(c.id, c.name, c.url)}
          // />
        ))}
      </div>
    </>
  );
};

export default Dashboards;
